import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { useEffect, useContext } from 'react';
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import  { UserContext }  from '../contexts/UserContext';
import { readConsumosInfo } from './dataController';

import {  Button, Input, } from "@material-ui/core";

import 'react-circular-progressbar/dist/styles.css';


export default function Consumos() {

  const contextuser = useContext(UserContext);

  const [state, setState] = React.useState({
    voiceMail: false,
    callForwarding: false,
    unconditionalCallForwarding: false,
    tripartiteCallWaiting: false,
    showPrivateNumbers: false
  });



useEffect(()=>{
  getServicesInfo();
}, [])



  const getServicesInfo = async() =>{
    //this.setState({backDOn:true})
    let user = contextuser.user;
    //'4428950891' //'5628282323' //
     //4613473137

     var data = {
        startdate : '2021011500',
        enddate : '2021013000',
        msisdn : '4613471005'
     }

    readConsumosInfo(data).then(resd =>{
       console.log(resd)
    }); 
    
    }

  return (
     <div>Resultados consumos</div>
  );
}

import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import { getToken, setToken, getData } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import {  } from '../components/dataController';

import AppLayout from '../components/AppLayout';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import NetCard from "../components/NetCard";
import AlertMessage from '../components/fields/AlertMessage';

import theme from '../theme.js';


const styles = theme => ({
    backdrop: {
        zIndex: 1000,
        color: '#fff',
      }
})


var user = null;


class NetView extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : false,
       
    } 

    static contextType = UserContext;


    async getProjectNode(){

        user = this.context.user;
        var id = this.props.match.params.id
        /*readNode(id).then(data =>{
            console.log(data,"DATA DE RETURN")
            this.setState({data:data,waiting:false})
        });*/
        
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };
    

    componentDidMount(){
        this.setState({'waiting':true})
        this.context.login(()=>{
            this.getProjectNode();
           this.setState({'waiting':false})
        });        
    }

    createProject(){

        user = this.context.user;
       

        this.setState({'waiting':true})
        var obj = {};
				obj.parentNode 	= this.props.match.params.id;  
				obj.label 	   	= "GNODE";
				obj.relType 	= "gnode_rel";
				obj.props 		= {label:"GROUP",public:false}		
				/*obj.childs =[
				 		{prefix:"b",relationName:'info_rel', label:'INFO',props :{order:'name',status:0}}
                     ]
                     */
 
         /*createNode(obj).then(val =>{
            this.getProjectNode();
         } );  */         
    }


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }


    render() {
        const { classes, history, session } = this.props;
        user = this.context.user;
        const data = this.state.data

        
      
        return (
            <AppLayout title={"Groups View"} >
                <div>

                <h2 style={{color:'#999'}}>Catálogos</h2>
                
                <Grid container spacing={1} direction="row" alignItems="flex-start" justify="flex-start" style={{ marginBottom:20 }}>   
                {data.childs.map( item => (
                    
                        <Grid  key={"g" + item.properties.intid} item xs={12} sm={6} md={3} lg={2} xl={2} >
                            <NetCard backDOn ={ () => {this.backDOn()}}
                                         backDOff ={ () => {this.backDOff()}}
                                         key={item.properties.intid}
                                         item ={item}
                                         info = {data.info}
                                         parentGroupNode={ () => {this.getProjectNode()}}
                                         msg={ msg1=>{this.setMsg(msg1)}}
                                        />
                        </Grid>
                   
                ))}
                </Grid>

                <Button color="primary" variant="contained" className={classes.button} onClick={() => {this.createProject()}}>{user ? user.lang.BT_addGroup: null}</Button>
                </div>

                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}
                
                <Backdrop className={classes.backdrop} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 

                
            </AppLayout>
        )
    }

}


export default withStyles(styles)(NetView);
import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import {  } from '../components/dataController';

import AppLayout from '../components/AppLayout';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import ProjectCard from "../components/projectCard";
import AlertMessage from '../components/fields/AlertMessage';


import theme from '../theme.js';


const styles = theme => ({
  
})


var user = null;


class ProjectView extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : false,
       
    } 

    static contextType = UserContext;


    async getRootNode(){

        user = this.context.user;
       /* readNode(user.rootNode).then(data =>{
            console.log(data,"DATA DE RETURN")
            this.setState({data:data,waiting:false})
        });*/
        
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };
    

    componentDidMount(){
        this.setState({'waiting':true})
        this.context.login(()=>{
            this.getRootNode();
           this.setState({'waiting':false})
        });        
    }

    createProject(){

        user = this.context.user;
       

        this.setState({'waiting':true})
        var obj = {};
				obj.parentNode 	= user.rootNode;  
				obj.label 	   	= "PROJECT";
				obj.relType 	= "project_rel";
				obj.props 		= {label:"PROJECT",public:false}		
				/*obj.childs =[
				 		{prefix:"b",relationName:'info_rel', label:'INFO',props :{order:'name',status:0}}
                     ] */
 
        /* createNode(obj).then(val =>{
            this.getRootNode();
         } );         */  
    }


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

   /* projectDetail =  (item) =>{
        this.props.history.push('/p/' + item)
    }*/

    render() {
        const {  history, session } = this.props;
        user = this.context.user;
        const data = this.state.data
        
 
        return (
            <AppLayout title={"Project View"} >
                <div>
                     {user ? <h2 style={{color:'#999'}}>Proyectos</h2> : null} 
                
                <Grid container spacing={2} direction="row" alignItems="flex-start" justify="flex-start" style={{marginBottom:20  }}>   
                {data.childs.map( item => (
                    
                       
                        <Grid   key={"g" + item.properties.intid} item xs={12} sm={6} md={3} lg={2} xl={2} >
                            <ProjectCard backDOn ={ () => {this.backDOn()}}
                                         backDOff ={ () => {this.backDOff()}}
                                         key={item.properties.intid}
                                         item ={item}
                                         history = {history}
                                         parentRootNode={ () => {this.getRootNode()}}
                                         msg={ msg1=>{this.setMsg(msg1)}}
                                        />
                        </Grid>
                      
                   
                ))}
                </Grid>


                <Button color="primary" variant="contained" onClick={() => {this.createProject()}}> + {user ? user.lang.BT_addProject: null}</Button>
                </div>

                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}
                
                <Backdrop  open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 

                
            </AppLayout>
        )
    }

}


export default withStyles(styles)(ProjectView);
import React, {useContext, List, useRef, useState, useEffect} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {TextField, CircularProgress, Grid} from '@material-ui/core';

import {} from './dataController'
import AskDialog from '../components/AskDialog'


const styles = theme => ({
    root: {
        //maxWidth: 250,
        minHeight : 180,
        backgroundColor : "#f1f1f1",
        '&:hover': {
          backgroundColor: '#fff'
        },
        marginRight : 20
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      avatar : {
        backgroundColor : "#1a3a63"
      },
      wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
      fabProgress: {
        color: "#999",
        position: 'relative',
        top: -51,
        left: 13,
        zIndex: 1,
      },
})  

function ProjectCard({item, classes, parentRootNode, msg, backDOn, backDOff, history}){

    const [expanded, setExpanded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editNameMode, setNameMode] = React.useState(null);
    const [waiting, setWaiting] = React.useState(null);
    const [confirm, setConfirm] = React.useState(null);


    const nameInput = useRef();

   
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    const _confirm = () =>{
      console.log("dialogo confirmación")
      setConfirm(true)
    }

    const _deleteNode = () =>{
        backDOn();
        let intid = item.properties.intid
        console.log(intid ,"ITEM")
       /* deleteNode({intid: intid}).then(val =>{
          backDOff();
          parentRootNode();
          msg("Proyecto borrado correctamente")
         } );  */         
    }

    const _editModeOn = () =>{
      setNameMode(true);
      //nameInput.current.focus() 
    }

    const _editModeOff = () =>{
      setNameMode(false);
      //nameInput.current.focus() 
    }

    const _renameNode = (e) =>{
      console.log(e)
      setWaiting(true);
      _editModeOff();

      let intid = item.properties.intid
     /* addProperty({params:{name: name}, intid: intid}).then(val =>{
        parentRootNode();
       
        setWaiting(false);
       } ); */
    }


      const itemDetail =  () =>{



        //window.location = '/dash#carrito';
      }

    let name = item.name;
    const _setName = (event,) => { 
      name = event.target.value
    }

        return(
          <div>
         <AskDialog title="Borrar" text="Confirma el borrado del proyecto?" onOkClick={_deleteNode} onCancel={()=>{setConfirm(false)}} status={confirm}/> 

            <Card className={classes.root}>
            <CardHeader 
              avatar={
                <Avatar className={classes.avatar} aria-label="project" variant="rounded" color="primary">
                  {item.PRECIOPUBLICO ? item.PRECIOPUBLICO : 'FTE'}
                </Avatar>
              }
              action={
                  <div>
                
                    </div>
              }

              

              title={item.NOMBRECOMERCIAL}
              subheader={item.ACCION}
            />
             {waiting && <CircularProgress size={38} className={classes.fabProgress} /> }
          {/*  <CardMedia
              className={classes.media}
              image="/static/images/cards/paella.jpg"
              title="Paella dish"
          />   */}
            <CardContent  onClick={() =>itemDetail()} >
              {editNameMode ? 
              <TextField inputRef={nameInput}  onChange={_setName}  
              onKeyUp={(event) => {
                console.log(event.key)
                if (event.key== 'Enter')
                    _renameNode(event.target.value);
            }}
              
              id="standard-basic" label="Nombre"  value={item.name}/>   : null  
              } 

              {!waiting ? 
              <div>
              <Grid container space={2}>
                <Grid item xs={6}>
                <Typography >
                  SMS: {item.SMS + item.SMSFUP} 
                </Typography>
                <Typography >
                  VOZ: {item.MINVOZ + item.MINFUP} 
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography >
                  MB : {item.MB + item.MBINT} 
                </Typography>

                <Typography >
                  DÍAS : {item.DIAS} 
                </Typography>
              
              </Grid>
                </Grid>
            
              </div>
              : null
              }
              

              
            </CardContent>
            <CardActions disableSpacing>
             
             {/* <IconButton
               
               className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              >
                <ExpandMoreIcon />
            </IconButton>  */}
            </CardActions>
         {/*   <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent  >
                <Typography paragraph>DETALLES:</Typography>
                <Typography paragraph>
                  COMPARTIR INTERNET {item.TETERING}
                </Typography>
                <Typography paragraph>
                  COMPARTIR INTERNET {item.TETERING}
                </Typography>
               
              </CardContent>
            </Collapse> */}
          </Card>
          </div>
        )
}


export default withStyles(styles)(ProjectCard);
import React, {useContext, List, useRef, useState, useEffect} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';

import {TextField, CircularProgress, Grid} from '@material-ui/core';

import {} from './dataController'
import AskDialog from '../components/AskDialog'


const styles = theme => ({
    root: {
        //maxWidth: 250,
        minHeight : 180,
        backgroundColor : "#f1f1f1",
        '&:hover': {
          backgroundColor: '#fff'
        },
        marginRight : 20
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },
      avatar : {
        backgroundColor : "#1a3a63",
        paddingRight : 10,
        paddingLeft : 10
      },
     
      wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
      fabProgress: {
        color: "#999",
        position: 'relative',
        top: -51,
        left: 13,
        zIndex: 1,
      },
      selected : {
        backgroundColor : "#ec6223 !important" ,
    }
})  

function RecargaItem({item,classes,selected}){

    console.log(item.selected,"selected")
    const [expanded, setExpanded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editNameMode, setNameMode] = React.useState(null);
    const [waiting, setWaiting] = React.useState(null);
    const [confirm, setConfirm] = React.useState(null);


    const nameInput = useRef();

   
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
      };



      const itemDetail =  () =>{


        console.log("listo para pagar")
        //window.location = '/dash#carrito';
      }

    let name = item.name;
    const _setName = (event,) => { 
      name = event.target.value
    }

    
    let styleB = item.selected ? classes.selected : classes.root;
    console.log(styleB,"selected1")

    
    return(
        <div>
          <Card className={[classes.root,styleB]}>
          <CardHeader 
            avatar={
              <Avatar style={{width:'auto'}} className={classes.avatar} aria-label="project" variant="rounded" color="primary">
                {item.PRECIOPUBLICO ? ('$' + item.PRECIOPUBLICO) : 'FTE'}
              </Avatar>
            }
            action={
                <div>
              
                  </div>
            }

            

            title={item.NOMBRECOMERCIAL}
            subheader={item.ACCION}
          />
           {waiting && <CircularProgress size={38} className={classes.fabProgress} /> }
        {/*  <CardMedia
            className={classes.media}
            image="/static/images/cards/paella.jpg"
            title="Paella dish"
        />   */}
          <CardContent  onClick={() =>itemDetail()} >
            
            {!waiting ? 
            <div>
            <Grid container space={2}>
              <Grid item xs={6}>
              {item.SMSFUP>0 ? (
                    <Typography >
                    SMS: {item.SMS + item.SMSFUP} 
                  </Typography>
              ) :null}
            
            {item.MINFUP>0 ? (
                    <Typography >
                   VOZ: {item.MINVOZ + item.MINFUP} 
                  </Typography>
              ) :null}
            </Grid>


            <Grid item xs={6}>
            {item.MB>0 ? (
                    <Typography >
                   {item.SMS + item.MB}  MB
                  </Typography>
              ) :null}
            
            {item.DIAS>0 ? (
                    <Typography >
                   DIAS: {item.DIAS} 
                  </Typography>
              ) :null}

             
            </Grid>
              </Grid>
          
            </div>
            : null
            }
            

            
          </CardContent>
          <CardActions disableSpacing>
           
           {/* <IconButton
             
             className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
            onClick={handleExpandClick}
            aria-expanded={expanded}
            aria-label="show more"
            >
              <ExpandMoreIcon />
          </IconButton>  */}
          </CardActions>
       {/*   <Collapse in={expanded} timeout="auto" unmountOnExit>
            <CardContent  >
              <Typography paragraph>DETALLES:</Typography>
              <Typography paragraph>
                COMPARTIR INTERNET {item.TETERING}
              </Typography>
              <Typography paragraph>
                COMPARTIR INTERNET {item.TETERING}
              </Typography>
             
            </CardContent>
          </Collapse> */}
        </Card>
        </div>
      )
}


export default withStyles(styles)(RecargaItem);
import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    cuadroC :{
        marginTop : 10,
        marginBottom : 10,
        
        
    },
    cuadroT : {
        
        height : 'auto',
        backgroundColor : "#f1f1fc",
        borderRadius : 4,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 20,
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
    },
    cuadroTitle:{
        fontSize: 18,     
        textAlign: 'center',
        marginBottom : 20
    },
    cuadroDesc : {
        fontSize: 14,
        color : '#333',
        textAlign: 'center'
    },
    cuadroPrecio:{
        textAlign : 'center',
        color : '#eb6324',
        fontSize : 30,
        fontWeight : 'bold',
       
    },
    cuadroPeriodo:{
        textAlign : 'center',
        color : '#eb6324',
        fontSize : 13,
        fontWeight : 'bold'
    },
    cuadroCostoSIM:{
        textAlign : 'center',
        fontSize : '14',
        fontWeight : 'bold',
        marginTop : 20,
        marginBottom : 10,
        paddingTop : 20,
        borderTop : '1px solid #ccc'
    },
    cuadroCar:{
        marginTop  : 16,
        marginLeft : 20,
        fontSize : 12
    },
    carCont:{
        minHeight: 140
    },
    cuadroBt:{
        marginTop : 20,
        textAlign : 'center',
        padding : 5,
        backgroundColor : '#eb6324',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 20,
        fontWeight : 'bold',
        cursor : 'pointer',
        verticalAlign:'middle'
    },
    title:{
        color : '#eb6324',
        fontSize : 34,
        marginTop : 40
    }

})
var user = null;

class Internet extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true, 
        plan1d : false,      
        plan2d : false,      
        plan3d : false,      
        plan4d : false,      
    } 

  

    componentDidMount(){
        this.setState({'waiting':true})
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };

    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
        window.location = "/dash#tienda"
    }

    alform = () =>{
        this.props.afif(true)
     }

    toogle_plan1d = () =>{
        this.setState(prevState => ({
            plan1d: !prevState.plan1d
          }));    
    }
    toogle_plan2d = () =>{
        this.setState(prevState => ({
            plan2d: !prevState.plan2d
          }));    
    }
    toogle_plan3d = () =>{
        this.setState(prevState => ({
            plan3d: !prevState.plan3d
          }));    
    }
    toogle_plan4d = () =>{
        this.setState(prevState => ({
            plan4d: !prevState.plan4d
          }));    
    }


    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            
           

            <Grid container alignItems="center" justify="center" spacing={0}>
                <Grid  item md={12}>
                    <img style={{width:'100%'}} src="/internet.png" />
                </Grid>

                <Container maxWidth='md'>
                <Grid  item md={12}>
                <div className={classes.title}>Planes de Telefónia Fija</div>
                </Grid>

               
                <Grid   container  spacing={1} alignItems="flex-start" justify="center" style={{flex:1,flexDirection:'row',marginTop:50, marginBottom:50}} >
                <Grid item  xs={12} sm={6} md={3} style={{justify:'center'}}>
                        <div className={classes.cuadroC}>
                        <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>FTE - 100</div>
                                <div className={classes.cuadroPrecio}>$100</div>
                                <div className={classes.cuadroPeriodo}>mensual/ IVA incluido</div>

                                <div className={classes.cuadroCostoSIM}>Costo SIM $80.00</div>

                                <div className={classes.carCont}>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}}/> 5 GB</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />1,500 minutos y 500 SMS</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />Vigencia 30 días</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5, color: 'green'}} />Datos para compartir</div>
                                </div>

                                
                                <div onClick={() => {this.alform()}} className={classes.cuadroBt}>CONTRATA <ArrowRightAltIcon style={{ verticalAlign:'middle'}} /></div>

                                <div style={{textAlign:'center',marginTop:10, cursor:'pointer'}} onClick={()=>{this.toogle_plan1d()}}>
                                    mas información 
                                    <div style={{textAlign:'center',marginTop:0, cursor:'pointer'}} >
                                    < ExpandMoreIcon />
                                    </div>
                                    </div>

                                {this.state.plan1d ? (
                                    <div>           
                                    <ul>
    <li>5,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>750 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá</li>
    <li>250 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>Cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>
                                </div>
                                ) :null}
                            </div>
                        </div>
                    </Grid>
                    
                    <Grid item  xs={12} sm={6} md={3} style={{ justify:'center'}}>
                        <div className={classes.cuadroC}>
                        <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>FTE - 200</div>
                                <div className={classes.cuadroPrecio}>$200</div>
                                <div className={classes.cuadroPeriodo}>mensual/ IVA incluido</div>

                                <div className={classes.cuadroCostoSIM}>Costo SIM $80.00</div>


                                <div className={classes.carCont}>   
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}}/> 40 GB</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />1,500 minutos y 1,000 SMS</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />Vigencia 30 días</div>
                                </div>
                               

                                <div onClick={() => {this.alform()}} className={classes.cuadroBt}>CONTRATA <ArrowRightAltIcon style={{ verticalAlign:'middle'}} /></div>

                                <div style={{textAlign:'center',marginTop:10, cursor:'pointer'}} onClick={()=>{this.toogle_plan2d()}}>
                                    mas información 
                                    <div style={{textAlign:'center',marginTop:0, cursor:'pointer'}} >
                                    < ExpandMoreIcon />
                                    </div>
                                    </div>

                                {this.state.plan2d ? (
                                    <div>           
                                    <ul>
    <li>20,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>20,000 megas para navegar en México a una velocidad máxima de navegación downlink de 512 Kbps.</li>
    <li>750 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá.</li>
    <li>500 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>No cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>
                                </div>
                                ) :null}

                            </div>
                        </div>
                    </Grid>

                    <Grid item  xs={12} sm={6} md={3} style={{}}>
                    <div className={classes.cuadroC}>
                        <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>FTE - 300</div>
                                <div className={classes.cuadroPrecio}>$300</div>
                                <div className={classes.cuadroPeriodo}>mensual/ IVA incluido</div>

                                <div className={classes.cuadroCostoSIM}>Costo SIM $80.00</div>

                                <div className={classes.carCont}>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}}/> 40 GB</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />1,500 minutos y 1,000 SMS</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />Vigencia 30 días</div>
                                <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5, color: 'green'}} />Datos para compartir</div>
                                </div>

                                <div onClick={() => {this.alform()}} className={classes.cuadroBt}>CONTRATA <ArrowRightAltIcon style={{ verticalAlign:'middle'}} /></div>


                                <div style={{textAlign:'center',marginTop:10, cursor:'pointer'}} onClick={()=>{this.toogle_plan3d()}}>
                                    mas información 
                                    <div style={{textAlign:'center',marginTop:0, cursor:'pointer'}} >
                                    < ExpandMoreIcon />
                                    </div>
                                    </div>

                                {this.state.plan3d ? (
                                    <div>           
                                   <ul>
    <li>20,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>20,000 megas para navegar en México a una velocidad máxima de navegación downlink de 512 Kbps.</li>
    <li>750 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá.</li>
    <li>500 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>Cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>
                                </div>
                                ) :null}

                            </div>
                        </div>
                    </Grid>

                    <Grid item  xs={12} sm={6} md={3} style={{}}>
                    <div className={classes.cuadroC}>
                        <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>FTE - 500</div>
                                <div className={classes.cuadroPrecio}>$500</div>
                                <div className={classes.cuadroPeriodo}>mensual/ IVA incluido</div>

                                <div className={classes.cuadroCostoSIM}>SIM incluido*</div>
                                
                                <div className={classes.carCont}>
                                    <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14, marginRight:5}}/> 100 GB</div>
                                    <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14, marginRight:5}} />10,000 minutos y 10,000 SMS</div>
                                    <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5}} />Vigencia 30 días</div>
                                    <div className={classes.cuadroCar}><CheckCircleIcon style={{fontSize:14,  marginRight:5, color: 'green'}} />Datos para compartir</div>
                                </div>

                                <div onClick={() => {this.alform()}} className={classes.cuadroBt}>CONTRATA <ArrowRightAltIcon style={{ verticalAlign:'middle'}} /></div>

                                <div style={{textAlign:'center',marginTop:10, cursor:'pointer'}} onClick={()=>{this.toogle_plan4d()}}>
                                    mas información 
                                    <div style={{textAlign:'center',marginTop:0, cursor:'pointer'}} >
                                    < ExpandMoreIcon />
                                    </div>
                                    </div>

                                {this.state.plan4d ? (
                                    <div>           
                                   <ul>
    <li>50,000 megas para navegar en México, EUA y Canadá a una velocidad Best Effort</li>
    <li>50,000 megas para navegar en México a una velocidad máxima de navegación downlink de 512 Kbps.</li>
    <li>5,000 minutos para llamadas salientes a destino nacional y 750 minutos para llamadas salientes con origen en EUA y Canadá y destino a México, EUA y Canadá.</li>
    <li>5,000 SMS salientes a destino nacional y 250 SMS salientes con origen en EUA y Canadá y destino a México, EUA y Canadá).</li>
    <li>El plan no incluye datos para navegación sin costo en redes sociales.</li>
    <li>Cuenta con la función de Tethering o Hotspot (compartir datos).</li>
    <li>Vigencia de la oferta es de 30 días naturales. Los datos, llamadas y SMS no son acumulables para la siguiente recarga.</li>
    <li>Todos los precios incluyen IVA y están expresados en Moneda Nacional.</li>
    <li>Servicio disponible para equipos homologados y compatible con la Banda 28. Asegúrate que tu equipo es compatible aquí <a href="https://sim.fte.mx/" target="_blank">(https://sim.fte.mx/).</a></li>
</ul>
                                </div>
                                ) :null}
                                


                            </div>

                            
                        </div>
                    </Grid>

                   
                   
                </Grid>

                <a href="https://sim.fte.mx" target="_blank"><div style={{backgroundColor:"#323b5c",borderRadius:6,padding:10,marginTop:20,color:'#fff',textAlign:'center', fontSize:18}}> Verifica la compatibilidad de tu teléfono</div></a>

               
                
              

             


                    {/* 
                    <div>
                 <h5>Información general FTE</h5>
                    <div style={{display:'flex',lineHeight:'30px'}}><div>Carta Mínima de derechos &nbsp;</div> <a href="/Carta Mínima de Derechos.pdf" target="_blank"> leer documento</a></div>
                    <div style={{display:'flex',lineHeight:'30px'}}><div>Colaboración con la justicia &nbsp;</div> <a href="/Colaboración con la justicia.pdf" target="_blank"> leer documento</a></div>
                    <div style={{display:'flex',lineHeight:'30px'}}><div>Pólítica de uso justo &nbsp;</div> <a href="/Política de Uso Justo.pdf" target="_blank"> leer documento</a></div>
                    </div>
                */}

                </Container>
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Internet);
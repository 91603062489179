import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Updated import
import PropTypes from 'prop-types';
import {
    AppBar,
    Avatar,
    Button,
    Grid,
    Hidden,
    IconButton,
    Tab,
    Tabs,
    Toolbar,
    Tooltip,
    Typography,
    Menu,
    MenuItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core/';
import {
    Help as HelpIcon,
    Menu as MenuIcon,
    Notifications as NotificationsIcon,
    Person as PersonIcon,
    ExitToApp as ExitIcon
} from '@material-ui/icons/';
import { withStyles } from '@material-ui/core/styles';

import { delToken } from '../functions.js';

// Function to log out and navigate
function LogOut(navigate) {
    delToken();
    navigate('/login');
}

const menu = [
    { id : 'user-menu', tooltip : 'Usuario', open : false, icon : <PersonIcon />, items : [
        { text : "Salir", icon : <ExitIcon />, func : (navigate) => { LogOut(navigate) } },
    ] },
]

const anchor_origin = { vertical: 'bottom', horizontal: 'left', }

const transform_origin = { vertical: 'top', horizontal: 'left', }

const lightColor = 'rgba(255, 255, 255, 0.7)';

const styles = theme => ({
    title : {
        color : theme.palette.common.white,
    },
    secondaryBar: {
        zIndex: 0,
        width : '100%',
    },
    menuButton: {
        marginLeft: -theme.spacing(1),
    },
    iconButtonAvatar: {
        color : theme.palette.common.white,
    },
    link: {
        textDecoration: 'none',
        color: lightColor,
        '&:hover': {
            color: theme.palette.common.white,
        },
    },
    button: {
        borderColor: lightColor,
    },
});

function AppHeader(props) {
    const { classes, title } = props;
    const navigate = useNavigate(); // Using the hook for navigation

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [menuItems, setMenuItems] = React.useState(menu);

    const showMenu = (id, target) => {
        setAnchorEl(target);
        const updatedMenu = menuItems.map(item => (
            item.id === id ? { ...item, open: true } : item
        ));
        setMenuItems(updatedMenu);
    };

    const hideMenu = (id) => {
        const updatedMenu = menuItems.map(item => (
            item.id === id ? { ...item, open: false } : item
        ));
        setMenuItems(updatedMenu);
    };

    return (
        <Grid className={classes.header} container spacing={1} alignItems="center">
            <Grid container alignContent='center' justify='center' style={{ flex: 1, flexDirection: 'row' }}>
                <Grid item lg={12} md={12} style={{ flexDirection: 'row' }}>
                    <img src="logofte.svg" onClick={() => { window.location = "/" }} style={{ height: 44, marginTop: 10, cursor: 'pointer' }} />
                </Grid>
            </Grid>
            {menuItems.map(({ id, tooltip, icon, open, items }, index) => (
                <Grid item key={id}>
                    <Tooltip title={tooltip}>
                        <IconButton aria-controls={id} className={classes.iconButtonAvatar} onClick={(event) => { showMenu(id, event.currentTarget) }}>
                            {icon}
                        </IconButton>
                    </Tooltip>
                    <Menu id={id} variant='menu' anchorEl={anchorEl} getContentAnchorEl={null} anchorOrigin={anchor_origin} transformOrigin={transform_origin} keepMounted open={open} onClose={() => { hideMenu(id) }}>
                        {items.map(({ text, url, func }, index) => (
                            <MenuItem key={id} component={url ? Link : null} to={url} onClick={() => { func(navigate) }}>
                                <ListItemIcon>
                                    {icon}
                                </ListItemIcon>
                                <ListItemText primary={text} />
                            </MenuItem>
                        ))}
                    </Menu>
                </Grid>
            ))}
        </Grid>
    );
}

export default withStyles(styles)(AppHeader);

import React, {useContext, List, useRef, useState, useEffect} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Collapse from '@material-ui/core/Collapse';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { red } from '@material-ui/core/colors';
import FavoriteIcon from '@material-ui/icons/Favorite';
import ShareIcon from '@material-ui/icons/Share';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import {TextField, CircularProgress, Button} from '@material-ui/core';

import {} from './dataController'
import AskDialog from '../components/AskDialog'
import Dialog from '../components/Dialog';
//import TemplateConfig from '../pages/templategenerator';

const styles = theme => ({
    root: {
        maxWidth: 250,
        minHeight : 180
      },
      expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
          duration: theme.transitions.duration.shortest,
        }),
      },
      expandOpen: {
        transform: 'rotate(180deg)',
      },

      avatar : {
        backgroundColor : "#414A5B"
      },
      wrapper: {
        margin: theme.spacing(1),
        position: 'relative',
      },
      fabProgress: {
        color: "#999",
        position: 'relative',
        top: -51,
        left: 13,
        zIndex: 1,
      },
})  

function NetCard({item, classes, parentGroupNode, msg, backDOn, backDOff}){

    const [expanded, setExpanded] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [editNameMode, setNameMode] = React.useState(false);
    const [waiting, setWaiting] = React.useState(false);
    const [confirm, setConfirm] = React.useState(false);
    const [configState, setConfig] = React.useState(false);

    const nameInput = useRef();

   
    const handleExpandClick = () => {
      setExpanded(!expanded);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
      };
    
    const handleClose = () => {
        setAnchorEl(null);
      };

    const _confirm = () =>{
      console.log("dialogo confirmación")
      setConfirm(true)
    }

    const _deleteNode = () =>{
        backDOn();
        let intid = item.properties.intid
        console.log(intid ,"ITEM")
        /*deleteNode({intid: intid}).then(val =>{
          backDOff();
          parentGroupNode();
          msg("Proyecto borrado correctamente")
         } );    */        
    }

    const _editModeOn = () =>{
      setNameMode(true);
      //nameInput.current.focus() 
    }

    const _editModeOff = () =>{
      setNameMode(false);
      //nameInput.current.focus() 
    }

    const _renameNode = (e) =>{
      console.log(e)
      setWaiting(true);
      _editModeOff();

      let intid = item.properties.intid
      /*addProperty({params:{name: name}, intid: intid}).then(val =>{
        parentGroupNode();
       
        setWaiting(false);
       } );  */
    }

    const _shareNode = (e) =>{

    }


    const _configNodeOpen = (e) =>{
        setConfig(true)
    }
    const _configNodeClose = (e) =>{
        setConfig(false)
    }

    let name = item.name;
    const _setName = (event,) => { 
      name = event.target.value
    }

        return(
          <div>
         <Dialog close={_configNodeClose} open={configState} save={()=>{console.log("saving")}}>
            
        </Dialog>  

         <AskDialog title="Borrar" text="Confirma el borrado del grupo?" onOkClick={_deleteNode} onCancel={()=>{setConfirm(false)}} status={confirm}/> 

            <Card className={classes.root}>
            <CardHeader
              avatar={
                <Avatar aria-label="group" className={classes.avatar} variant="rounded" color="primary">
                  {item.properties.name ? item.properties.name.slice(0,1).toUpperCase() : "G"}
                </Avatar>
              }
              action={
                  <div>
                <IconButton aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} aria-label="settings">
                  <MoreVertIcon />
                </IconButton>
                    <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={_confirm}>Delete Node</MenuItem>
                    <MenuItem onClick={_editModeOn}>Rename Node</MenuItem>
                    <MenuItem onClick={_shareNode}>Share Node</MenuItem>
                    <MenuItem onClick={_configNodeOpen}>Config Node</MenuItem>
                    
                    </Menu>
                    </div>
              }

              title={item.properties.name}
              subheader={item.properties.label}
            />
             {waiting && <CircularProgress size={38} className={classes.fabProgress} /> }
          {/*  <CardMedia
              className={classes.media}
              image="/static/images/cards/paella.jpg"
              title="Paella dish"
          />   */}
            <CardContent>
              {editNameMode ? 
              <TextField inputRef={nameInput}  onChange={_setName}  
              onKeyUp={(event) => {
                console.log(event.key)
                if (event.key== 'Enter')
                    _renameNode(event.target.value);
            }}
              
              id="standard-basic" label="Nombre"  value={item.name}/>   : null  
              } 

              {!waiting ? 
              <Typography variant="body2" color="textSecondary" component="p">
              Group info.
              </Typography> : null
              }
              

              
            </CardContent>
            <CardActions disableSpacing>
             
              <IconButton
               
               className={clsx(classes.expand, {
                [classes.expandOpen]: expanded,
              })}
              onClick={handleExpandClick}
              aria-expanded={expanded}
              aria-label="show more"
              >
                <ExpandMoreIcon />
              </IconButton>
            </CardActions>
            <Collapse in={expanded} timeout="auto" unmountOnExit>
              <CardContent>
                <Typography paragraph>Información:</Typography>
                <Typography paragraph>
                  Información estadística del nodo.
                </Typography>
               
              </CardContent>
            </Collapse>
          </Card>
          </div>
        )
}


export default withStyles(styles)(NetCard);
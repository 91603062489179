import React, { Component } from "react";
import { Link } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Container, Toolbar, Tabs, Tab, Grid } from "@material-ui/core";
import { ExitToApp } from "@material-ui/icons";
import { UserContext } from '../contexts/UserContext';

const styles = theme => ({
    toolbar: {
        backgroundColor: theme.palette.primary.main
    }
});

class TabsHeader extends Component {
    static contextType = UserContext;

    logout = () => {
        this.context.logout();
    }

    render() {
        const { classes, tabs, tab } = this.props;
        const tabsv = tabs || [];

        // Convert the hash-based tab to an index
        const activeTabIndex = tabsv.findIndex(({ url }) => url === tab);

        return (
            <Toolbar className={classes.toolbar}>
                <Container style={{ padding: 0 }}>
                    <Grid container>
                        <Grid item md={10} xs={12}>
                            <Tabs variant="fullWidth" value={activeTabIndex}>
                                {tabsv.map(({ id, name, url }) => (
                                    <Tab
                                        style={{ padding: 10 }}
                                        key={url}
                                        value={url}
                                        label={name}
                                        component={Link}
                                        to={url}
                                    />
                                ))}
                            </Tabs>
                        </Grid>

                        <Grid item onClick={this.logout} md={2} xs={12}>
                            <div
                                style={{
                                    marginTop: 12,
                                    cursor: 'pointer',
                                    float: 'right',
                                    color: '#fff',
                                    padding: 2,
                                    borderRadius: 4,
                                    paddingRight: 2,
                                    paddingLeft: 2,
                                    textAlign: 'right',
                                    "&:hover": {
                                        backgroundColor: '#323b5c',
                                        color: "#fff !important"
                                    }
                                }}
                            >
                                {this.props.logout ? (
                                    <React.Fragment>
                                        <ExitToApp tooltip={"Cerrar sesión"} style={{ fill: "#eb6324", marginLeft: 10, marginRight: 0 }} />
                                        <div style={{ marginTop: 0, float: 'left' }}>Cerrar sesión</div>
                                    </React.Fragment>
                                ) : null}
                            </div>
                        </Grid>
                    </Grid>
                </Container>
            </Toolbar>
        );
    }
}

export default withStyles(styles)(TabsHeader);

import React from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormHelperText from '@material-ui/core/FormHelperText';
import Switch from '@material-ui/core/Switch';
import { useEffect, useContext } from 'react';
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import  { UserContext }  from '../contexts/UserContext';
import { readServicesInfo, updateService } from './dataController';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, Input, Radio,RadioGroup} from "@material-ui/core";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import TextField from '../components/fields/TextField';
import TextLabel from '../components/fields/TextLabel';
import ButtonField from '../components/fields/ButtonField';
import AlertMessage from './fields/AlertMessage';
import Select from 'react-select';

const styles = theme => ({
  button: {
    backgroundColor: theme.palette.background.paper,
  },
});

const Servicios = props => {

  const contextuser = useContext(UserContext);
  const { classes } = props;

  
const customStyles = {
  option: (provided, state) => ({
    ...provided,
      fontSize : 16
  }),
 
  singleValue: (provided, state) => {
    const opacity = state.isDisabled ? 0.5 : 1;
    const transition = 'opacity 300ms';

    return { ...provided, opacity, transition };
  }
}

 const payloadT = {
    "msisdn": "",
    "voiceMail": "0",
    "msisdn_voiceMail": "",
    "callForwarding": "0",
    "msisdn_callForwarding": "",
    "unconditionalCallForwarding": "0",
    "msisdn_unconditionalCallForwarding": "",
    "tripartiteCallWaiting": "0",
    "showPrivateNumbers": "0"
 }

  const [tripartiteCallWaiting,setTripartiteCallWaiting] = React.useState(false);
  const [showPrivateNumbers,setShowPrivateNumbers] = React.useState(false);



  const [inputState,setInputState] = React.useState({
      msisdn_services : ""
  });

const [desvio,setDesvio] = React.useState(null);
const [waiting,setWaiting] = React.useState(false);
const [phoneNumber,setPhonenumber] = React.useState(null);
const [data,setData] = React.useState(null);

const [selectedNum,setSelectedNum] = React.useState({value:null});
const [numbersArr,setNumbers] = React.useState([]);

const [msgVar,setMsg] = React.useState({
    msg: false,
    message: "",
    severity: "success"
});

 useEffect(()=>{
    init();
}, [])

useEffect(()=>{
  
  if(selectedNum!=null){
    console.log(selectedNum,"SelectedNum effect")
    setWaiting(true)
    getServicesInfo();
    
  }
  
}, [selectedNum])


const init = async () =>{
  setWaiting(true);
  await contextuser.loginInfo( res=>{
    var options = [];

            var alias = ''
    for (let index = 0; index < res.numbers.length; index++) {

      if(res.numbers[index].alias!=undefined &&  res.numbers[index].alias!=''){
          alias =  "   --    " + res.numbers[index].alias ;
      }

      if(res.numbers[index].status==0 || res.numbers[index].status==undefined)
      {
          options.push({value:res.numbers[index].msisdn,label:res.numbers[index].msisdn + alias})
      }
    }

    setNumbers(options)
    setTimeout(()=>{
      setSelectedNum({value:options[0].value, label:options[0].label});
    },100)
    

    
    setWaiting(false);

  })
}

const comboChange =(event) =>{
  console.log(event.value,'target')
  
    
  
  for (let index = 0; index < numbersArr.length; index++) {
      if(numbersArr[index].value==event.value){
       
      //setData(data)
        setSelectedNum({value:numbersArr[index].value,label:numbersArr[index].label})
       
        //this.startNumberInfo(event.value,this.context.user.numbers[index].icc)
      }
  }
}

  const handleInput = (event) => {
      console.log("vent", event.target.value)
    setInputState({ ...inputState, [event.target.name]: event.target.value });
  };

  const handleRadioChange = (e) =>{
    let value = e.target.value
    setDesvio(value)
    
  }




  const handleServices = () =>{
    var deep = JSON.parse (JSON.stringify(payloadT))
    console.log(desvio,"desvio tipo")
    if(desvio!="ninguno"){
      deep[desvio] = "1"
      deep["msisdn_" + desvio] = String(inputState.msisdn_services)
    }else{

      if(data.voiceMail=="SI") {deep['voiceMail'] = "2" };
      if(data.callForwarding=="SI") {deep['callForwarding'] = "2" };
      if(data.unconditionalCallForwarding=="SI") {deep['unconditionalCallForwarding'] = "2" };

    }
    
    deep.msisdn = phoneNumber;
    setWaiting(true)
    updateService(deep).then(e=>{
      setWaiting(false)
      setMsg({message : "Servicio actualizado",severity:"success",msg:true})
    })
  } 

  //  TRIPARTIPA
const handleTripartita = (event) => {
  setTripartiteCallWaiting(event.target.checked);
  var deep = JSON.parse (JSON.stringify(payloadT))
  deep.msisdn = phoneNumber;
  if(event.target.checked){
    deep.tripartiteCallWaiting="1"
  }else{
    deep.tripartiteCallWaiting="2"
  }
  setWaiting(true)
    updateService(deep).then(e=>{
      setWaiting(false)
      setMsg({message : "Llamada tripartita actualizada",severity:"success",msg:true})
    })
};

// NUMEROS PRIVADOS
const handlePrivate = (event) => {
  console.log(event,"private")
    setShowPrivateNumbers(event.target.checked);
    var deep = JSON.parse (JSON.stringify(payloadT))
    deep.msisdn = phoneNumber;
    if(event.target.checked){
      deep.showPrivateNumbers="1"
    }else{
      deep.showPrivateNumbers="2"
    }
    setWaiting(true)
    updateService(deep).then(e=>{
      setWaiting(false)
      setMsg({message : "Servicio actualizado",severity:"success",msg:true})
    })
};


const closeMsg = () =>{
  setMsg({msg:false})
}


  const getServicesInfo = async() =>{
       
  
    let phoneNumber = selectedNum.value;
    setPhonenumber(phoneNumber)
    //'4428950891' //'5628282323' //
     //4613473137

    readServicesInfo(phoneNumber).then(data =>{
      setWaiting(false);
      setData(data)

      console.log(data,"data de regreso")

      if(data.voiceMail=="SI") { setDesvio('voiceMail')};
      if(data.callForwarding=="SI") { setDesvio('callForwarding')};
      if(data.unconditionalCallForwarding=="SI") { setDesvio('unconditionalCallForwarding')};

      if(data.unconditionalCallForwarding=="NO" && data.callForwarding=="NO" && data.voiceMail=="NO" ) { setDesvio('ninguno')}

      if(data.showPrivateNumbers=="SI") { setShowPrivateNumbers(true)} else { setShowPrivateNumbers(false)};
      if(data.tripartiteCallWaiting=="SI") { setTripartiteCallWaiting(true)} else { setTripartiteCallWaiting(false) };
     
      console.log("STATE FINAL ",showPrivateNumbers,tripartiteCallWaiting)

      if(data.msisdn_callForwarding!="") { setInputState({msisdn_services:data.msisdn_callForwarding})};
      if(data.msisdn_unconditionalCallForwarding!="") { setInputState({msisdn_services:data.msisdn_unconditionalCallForwarding})};
      if(data.msisdn_voiceMail!="") { setInputState({msisdn_services:data.msisdn_voiceMail})};

    }); 
    
    }

  return (

    <div>
    {msgVar.msg ? <AlertMessage open={msgVar.msg} message={msgVar.message} severity={msgVar.severity} onClose={closeMsg} /> : null}
    
    <Backdrop  style={{zIndex:1000}} open={waiting} onClick={() =>{}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 


    {!waiting ? (



         <FormControl component="fieldset">

          <Select 
            value = {selectedNum}
            placeholder = {'Seleccione un número'}
            noOptionsMessage = {'No hay números registrados'}
            styles={customStyles}
            style={{marginTop:20, marginBottom:20}}
            classNamePrefix = {customStyles}
            options={numbersArr} 
            onChange={e => {comboChange(e)}}
          />


         <FormGroup>
 
 
         <h2>Desvió de llamadas</h2>  
         <RadioGroup row aria-label="desvio" name="desvio" value={desvio} onChange={handleRadioChange}>
           <FormControlLabel value="voiceMail" control={<Radio />} label="Correo de Voz" />
           <FormControlLabel value="callForwarding" control={<Radio />} label="Desvío de llamadas" />
           <FormControlLabel value="unconditionalCallForwarding" control={<Radio />} label="Desvío directo" />
           {}
           <FormControlLabel value="ninguno" control={<Radio />} label="Desactivado" />
         </RadioGroup>
 
         <FormGroup>
                 {desvio!='ninguno' ? (
                     <Input value={inputState.msisdn_services  } placeholder="Número telefónico (10 dígitos)" name="msisdn_services" label='Número telefónico' onChange={handleInput} />
                 ) : null}
                 
                 <Button style={{backgroundColor:'#eb6324', marginTop:10,float:'right',color:'#fff'}} onClick={handleServices}>Guardar cambios</Button>                   
         </FormGroup>
 
              <h2 style={{marginTop:40, marginBottom:0}}>Llamada Tripartita</h2>  
             <FormControlLabel style={{marginTop:0}}
             control={<Switch checked={tripartiteCallWaiting || false} onChange={handleTripartita} name="tripartiteCallWaiting" />}
             label="Llamada tripartita "
             />
 
             <h2 style={{marginTop:40, marginBottom:0}}>Mostrar números privados</h2>  
             <FormControlLabel 
             control={<Switch checked={showPrivateNumbers  || false} onChange={handlePrivate} name="showPrivateNumbers" />}
             label="Mostrar números privados"
             />
             </FormGroup>
 
             <FormGroup>
             <FormHelperText style={{marginTop:30, marginBottom:30}} >Algunos de los servicios requieren que tu teléfono sea reiniciado</FormHelperText>
             </FormGroup>
 
         </FormControl>
    ): <h2>Obteniendo servicios FTE ...</h2>}
   

        </div>
  );
}


export default Servicios;
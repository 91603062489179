import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./pages/home";
import Login from "./pages/login";
import Node from "./pages/node";
import ProjectView from "./pages/projectview";
import Netview from "./pages/netview";
import Signup from "./pages/signup";
import Dashboard from "./pages/dashboard";
import Aviso from './contexts/Webpages/Aviso';
import Terminos from './contexts/Webpages/Terminos';
import Carta from './contexts/Webpages/Carta';
import Colaboracion from './contexts/Webpages/Colaboracion';
import UsoJusto from './contexts/Webpages/UsoJusto';
import Gestion from './contexts/Webpages/Gestion';

import { UserProvider } from "./contexts/UserContext";

//cross-env HTTPS=true SSL_CRT_FILE=./cert.pem SSL_KEY_FILE=./key.pem


const App = () => {
    return (
        <UserProvider>
            <Router>
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route exact path="/" element={<Home />} />
                    <Route exact path="/aviso" element={<Aviso />} />
                    <Route exact path="/terminos" element={<Terminos />} />
                    <Route exact path="/carta" element={<Carta />} />
                    <Route exact path="/colaboracion" element={<Colaboracion />} />
                    <Route exact path="/usojusto" element={<UsoJusto />} />
                    <Route exact path="/gestion" element={<Gestion />} />
                    <Route exact path="/dash" element={<Dashboard />} />
                    <Route path="/cuentac" element={<Signup />} />
                </Routes>
            </Router>
        </UserProvider>
    );
};

export default App;

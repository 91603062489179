import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
   title : {

   }

})
var user = null;

class Distribuidor extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,     
    } 

  

    componentDidMount(){
        this.setState({'waiting':true})
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };

    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    
    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            
           

            <Grid container alignItems="center" justify="center" spacing={0}>
                <Grid  item md={12}>
                    <img style={{width:'100%'}} src="/internet.png" />
                </Grid>

                <Container maxWidth='md'>
                <Grid  item md={12}>
                <h1>Conviertete en un distribuidor FTE en tu región</h1>
                </Grid>

<h2>Por favor contacta con un asesor en el botón de la derecha</h2>
                <call-us
style={{zIndex: 99999,'--call-us-form-header-background':'#373737',
'--call-us-main-button-background':'#0596d4','--call-us-client-text-color':'#d4d4d4',
'--call-us-agent-text-color':'#eeeeee','--call-us-form-height':'330px'}}
id="wp-live-chat-by-3CX"
channel-url="https://fobos.3cx.us"
files-url="https://fobos.3cx.us"
minimized="true"
animation-style="slideUp"
party="luissuazo"
minimized-style="BubbleRight"
allow-call="false"
allow-video="false"
allow-soundnotifications="true"
enable-mute="true"
enable-onmobile="true"
offline-enabled="false"
enable="true"
ignore-queueownership="false"
authentication="both"
operator-name="Support"
show-operator-actual-name="true"
channel="phone"
aknowledge-received="true"
gdpr-enabled="false"
gdpr-message="I agree that my personal data to be processed and for the use of cookies in order to engage in a chat processed by COMPANY, for the purpose of Chat/Support for the time of 30 day(s) as per the GDPR."
message-userinfo-format="both"
message-dateformat="both"
start-chat-button-text="Chat"
window-title="Distribuye FTE"
button-icon-type="Default"
invite-message="Hola %NAME%, ¿como te puedo ayudar hoy?"
authentication-message="Por favor indica tu nombre, correo y teléfóno"
unavailable-message="We are away, leave us a message!"
offline-finish-message="We received your message and we'll contact you soon."
ending-message="Tu sesión de chat ha finalizado, si lo requieres puedes volver a chatear con nosotros."
greeting-visibility="none"
greeting-offline-visibility="none"
chat-delay="2000"
offline-name-message="Could we have your name?"
offline-email-message="Could we have your email?"
offline-form-invalid-name="I'm sorry, the provided name is not valid."
offline-form-maximum-characters-reached="Maximum characters reached"
offline-form-invalid-email="I'm sorry, that doesn't look like an email address. Can you try again?"
enable-direct-call="true"
enable-ga="false"
lang="es"
>
</call-us>
               

                </Container>
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Distribuidor);

import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import 'react-circular-progressbar/dist/styles.css';


const styles = theme => ({})

class Contacto extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 


    componentDidMount(){
        this.setState({'waiting':true})
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };

    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
        window.location = "/dash#tienda"
    }


    render() {
        const {  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            <div>


                   
                    <h1>Hola Contacto</h1>

                   
                
            </div>
        )
    }

}


export default withStyles(styles)(Contacto);
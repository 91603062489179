import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';
import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo } from './dataController';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import ProjectCard from "./projectCard";
import AlertMessage from './fields/AlertMessage';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../theme.js';


const styles = theme => ({
  
})

var user = null;


class Facturacion extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,
        dataUser : {}       
    } 

    static contextType = UserContext;


    componentDidMount(){
        this.setState({'waiting':false})
        this.context.login(()=>{
           //this.setState({'waiting':false})
        });        
    }


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }


    render() {
        const {  history, session } = this.props;
        user = this.context.user;
        const data = this.state.data
        
 
        return (
            <div>

                <Backdrop  style={{zIndex:1000}} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 


                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}

                {this.state.waiting ? "": 
                    <div>


                    <h1>Todavía no tiene facturas generadas</h1>  

                    <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    style={{marginTop : 30}}
                    >
                   
                    </Grid>

                    

                    </div>
                }     
            </div>
        )
    }

}


export default withStyles(styles)(Facturacion);
import React, { Component } from "react";
import { Link} from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';
import { Container, AppBar, Toolbar, Tabs, Tab } from "@material-ui/core";

const styles = theme => ({
    toolbar : {
        backgroundColor : 'transparent'
    },
    indicator: {
            height: 5,
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
            backgroundColor: '#ff8000',
    },
    root :{
        color : '#333',
        minWidth : 121,
    }
    
});

class TabsWeb extends Component {

    componentWillMount() {

    }

    render(){
        const { classes, history, tabs, tab } = this.props;

        return (

            <Toolbar className={classes.toolbar} >
              <Container style={{padding:0}}>
              <Tabs value={tab} classes={{indicator:classes.indicator, root: classes.root}}>
                {tabs.map(({ id, name, url }) => (
                  <Tab style={{minWidth:60}} key={url} value={url} label={name} component={Link} to={url} />
                ))}
              </Tabs>
              </Container>
            </Toolbar>
        )
    }
}



export default withStyles(styles)(TabsWeb);
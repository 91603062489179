
import { getToken, getData, getDataSGT, getDataOUT } from '../functions.js';

export async function readUserInfo(msisdn,signal){
        let token = getToken();
        let data = {}
        let response = await getData(token, '/altan/profile/' + msisdn, data , 'GET',signal);
        return response;
}

export async function readUserANY(msisdn,icc,signal){
    let token = getToken();
    let data = {msisdn:msisdn,icc:icc}
    let response = await getData(token, '/altan/profileany/', data , 'POST',signal);
    return response;
}


export async function readConsumosInfo(data){
    let token = getToken();
    let response = await getData(token, '/altan/consumos/', data , 'POST');
    return response;
}


export async function readUserView(msisdn){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/altan/profile360/' + msisdn, data , 'GET');
    return response;
}



export async function readUserData(){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/users/dataUser/' , 'GET');
    return response;
}


export async function d_logout(){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/users/logout/' , 'GET');
    return response;
}


export async function signup(username,password,icc,nombre,apellidos){
    
    let token = getToken();
    let response = await getData(token, '/altan/usersignup/', {username:username,password:password,icc:icc,nombre:nombre,apellidos:apellidos} , 'POST');
    return response;
}


export async function readOfferInfo(offerid){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/altan/offer/' + offerid, data , 'GET');
    return response;
}

export async function readOffers(initialOffer){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/altan/offers/' + initialOffer, data , 'GET');
    return response;
}

export async function readServicesInfo(number){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/altan/services/' + number, data , 'GET');
    return response;
}

export async function updateService(data){
    console.log(data,"PAYLOAD")
    let token = getToken();
    let response = await getData(token, '/altan/serviceupdate/', data , 'POST');
    return response;
}



export async function updateUserInfo(data){
    let token = getToken()
    let response = await getData(token, '/users/updateuser/', data , 'POST');
    return response
}

// CART
export async function readCart(user){
    let token = getToken();
    let data = {}
    let response = await getData(token, '/cart/read/' , data , 'GET');
    return response;
}

export async function updateCart(id,data){
    let token = getToken();
    let response = await getData(token, '/cart/update/' + id, data , 'POST');
    return response;
}



// EXTERNAL

export async function checkICC(icc){
    console.log("checkging SIM")
    let response = await getDataSGT('/sims/item/' + icc , {} , 'GET');
    return response;
}


export async function getAvisos(){
    let token = getToken();
    let response = await getDataSGT('/avisos/active', {} , 'GET');
    return response;
}
// LOCAL DATA


//OPENPAY

export async function d_openInfo(data){
    let token = getToken();
    let response = await getData(token, '/users/open_userinfo', data , 'POST');
    return response;
}

export async function d_createUser(data){
    let token = getToken();
    let response = await getData(token, '/users/open_createClient', data , 'POST');
    return response;
}

export async function d_cardList(data){
    let token = getToken();
    let response = await getData(token, '/users/open_cardList', data , 'POST');
    return response;
}

export async function d_susList(data){
    let token = getToken();
    let response = await getData(token, '/users/open_susInfo', data , 'POST');
    return response;
}

export async function d_payment(data){
    let token = getToken();
    let response = await getData(token, '/users/open_payment_client', data , 'POST');
    return response;
}

export async function d_suscription(data){
    let token = getToken();
    let response = await getData(token, '/users/open_payment_suscription', data , 'POST');
    return response;
}

export async function d_cancelsubs(data){
    let token = getToken();
    let response = await getData(token, '/users/open_payment_cancelsuscription', data , 'POST');
    return response;
}



export async function d_saveCard(data){
    let token = getToken();
    let response = await getData(token, '/users/open_savecard', data , 'POST');
    return response;
}

export async function d_removeCard(cardId){
    let token = getToken();
    let response = await getData(token, '/users/open_removeCard/' + cardId, {} , 'POST');
    return response;
}


//CONEKTA

export async function d_conektaInfo(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_userinfo', data , 'POST');
    return response;
}

export async function d_conekta_createUser(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_createClient', data , 'POST');
    return response;
}

export async function d_conekta_iframe(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_payment_iframe', data , 'POST');
    return response;
}

export async function d_conekta_cardList(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_cardList', data , 'POST');
    return response;
}

export async function d_conekta_susList(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_susInfo', data , 'POST');
    return response;
}

export async function d_conekta_payment(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_payment_client', data , 'POST');
    return response;
}

export async function d_conekta_suscription(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_payment_suscription', data , 'POST');
    return response;
}

export async function d_conekta_cancelsubs(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_payment_cancelsuscription', data , 'POST');
    return response;
}

export async function d_conekta_saveCard(data){
    let token = getToken();
    let response = await getData(token, '/users/conekta_savecard', data , 'POST');
    return response;
}

export async function d_conekta_removeCard(cardId){
    let token = getToken();
    let response = await getData(token, '/users/conekta_removeCard/' + cardId, {} , 'POST');
    return response;
}
//-----------------------------------------------------------------------------------------


// ACOUNT NUMBERS
export async function d_addNumber(data){
    let token = getToken();
    let response = await getData(token, '/altan/addNumber', data , 'POST');
    return response;
}

export async function d_editNumber(data){
    let token = getToken();
    let response = await getData(token, '/altan/editNumber', data , 'POST');
    return response;
}

export async function d_checkCode(data){
    let token = getToken();
    let response = await getData(token, '/altan/checkCode', data , 'POST');
    return response;
}

export async function d_removeNumber(data){
    let token = getToken();
    let response = await getData(token, '/altan/removeNumber', data , 'POST');
    return response;
}

// OXXO

export async function d_oxxorefr(data){
    let token = getToken();
    let response = await getData(token, '/users/oxxo', data , 'POST');
    return response;
}

export async function d_paynetrefr(data){
    let token = getToken();
    let response = await getData(token, '/users/open_store', data , 'POST');
    return response;
}

export async function d_codirefr(data){
    let token = getToken();
    let response = await getData(token, '/users/open_codi', data , 'POST');
    return response;
}

export async function recuperacion(username){
    let token = getToken();
    let data = {}
    let response = await getDataOUT('/users/recovery/' , {username: username }, 'POST');
    return response;
}
// LOCAL DATA


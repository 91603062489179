import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Updated import
import PropTypes from 'prop-types';
import clsx from 'clsx';

import {
    Divider,
    Drawer,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core/';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';

import { withStyles } from '@material-ui/core/styles';
import { home_sections } from '../config.js';

const styles = theme => ({
    categoryHeader: {
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: 30,
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#eb6324',
            color: '#fff',
        },
    },
    mifte: {
        marginTop: 5,
        cursor: 'pointer',
        paddingTop: theme.spacing(1),
        paddingLeft: 30,
        fontSize: 18,
        "&:hover": {
            backgroundColor: '#323b5c',
            color: "#fff !important",
        }
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    navlink: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
    drawer: {
        root: {
            backgroundColor: '#fff',
        }
    },
    drawerOpen: {
        width: 350,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    drawerClose: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
        width: theme.spacing(7) + 1,
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9) + 1,
        },
    }
});

function WebNavigator(props) {
    const { classes } = props;
    const navigate = useNavigate(); // Hook for navigation

    const navigateTo = (link) => {
        props.retf(false); // Close the drawer
        if (window.location.pathname !== link) {
            navigate(link);
        }
    };

    const toggleDrawer = (event) => {
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return;
        }
        props.retf(false);
    };

    const handleClose = () => {
        props.retf(false);
    };

    return (
        <div>
            <Drawer className={classes.drawer} onClose={handleClose} open={props.open} onKeyDown={toggleDrawer}>
                <List disablePadding>
                    <img src="logofte.svg" style={{ height: 44, marginTop: 30, marginLeft: 30 }} />
                    <div style={{ marginTop: 30 }}>
                        {home_sections.map(({ id, name, url }) => (
                            <React.Fragment key={id}>
                                <ListItem className={classes.categoryHeader} onClick={() => { navigateTo(url) }}>
                                    <ListItemText
                                        classes={{
                                            primary: '#333',
                                        }}
                                    >
                                        {name}
                                    </ListItemText>
                                </ListItem>
                            </React.Fragment>
                        ))}
                    </div>
                    <ListItem className={classes.mifte} onClick={props.mifte}>
                        <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap', fontWeight: 'bold' }}>
                            Mi FTE <AssignmentIndIcon style={{ fill: "#eb6324", marginLeft: 10 }} />
                        </div>
                    </ListItem>
                </List>
            </Drawer>
        </div>
    );
}

export default withStyles(styles)(WebNavigator);

import React from 'react';
import { createRoot } from 'react-dom/client'; // Import createRoot from react-dom/client
import CssBaseline from '@material-ui/core/CssBaseline';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import HttpsRedirect from 'react-https-redirect';

// Get the root DOM node
const container = document.getElementById('root');
const root = createRoot(container); // Use createRoot

root.render(
    <HttpsRedirect>
        <CssBaseline />
        <App />
    </HttpsRedirect>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

import { url_server } from '../config.js';
import { setToken, delToken } from '../functions.js';
import React, { useState } from 'react';
import {
    Container,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress
} from '@material-ui/core/';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { useLocation, useNavigate } from "react-router-dom";
import AppLayout from '../components/AppLayout';
import TextField from '../components/fields/TextField';
import CheckBoxField from '../components/fields/CheckBoxField';
import ButtonField from '../components/fields/ButtonField';
import AlertMessage from '../components/fields/AlertMessage';

const useStyles = makeStyles((theme) => ({
    paper: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%',
        marginTop: theme.spacing(1),
        textAlign: 'center',
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

const Login = () => {
    const classes = useStyles();
    const location = useLocation(); // useLocation hook to get location data
    const currentDomain = window.location.hostname;
    const isDashUrl = location.pathname.includes('/dash');
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [remember, setRemember] = useState(true);
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(undefined);
    const [waiting, setWaiting] = useState(false);

    const login = async (e) => {
        setWaiting(true);
        e.preventDefault();
        try {
            const response = await fetch(url_server + '/users/login', {
                method: 'GET',
                credentials: 'include',
                headers: new Headers({
                    'Content-Type': 'application/json',
                    'Username': username,
                    'Password': password,
                    'Remember': remember,
                }),
            });

            switch(response.status) {
                case 200: {
                    const data = await response.json();
                    if(data.token) {
                        setToken(data.token);
                        console.log(currentDomain,"currentDomain");
                        if(currentDomain == "mi.fte.mx"|| currentDomain=="localhost" ) {
                            window.location = '/dash';
                        } else {
                            window.location = 'https://mi.fte.mx/dash';
                            
                        }
                    } else {
                        setWaiting(false);
                        setError(true);
                        setMessage('Usuario o contraseña incorrectos.');
                    }
                    break;
                }
                case 401:
                    delToken();
                    setWaiting(false);
                    setError(true);
                    setMessage('Usuario o contraseña incorrectos.');
                    break;
                default:
                    delToken();
                    setWaiting(false);
                    setError(true);
                    setMessage('Error al iniciar sesión.');
                    break;
            }
        } catch (error) {
            console.log(error);
            setError(true);
            setMessage('Error al iniciar sesión.');
        }
    };

    return (
        <div>
            <Grid container spacing={1} direction="row" alignItems="flex-start" justify="center">
                <Grid item xs={12}>
                    <Card className={classes.card}>
                        <CardContent>
                            <Typography component="h1" variant="h5">
                                ingresar a mi cuenta FTE
                            </Typography>
                            <form className={classes.form} onSubmit={login} noValidate>
                                <TextField value={username} label='Usuario' onChange={(e) => setUsername(e.target.value)} />
                                <TextField value={password} label='Contraseña' onChange={(e) => setPassword(e.target.value)} type='password' />
                                <CheckBoxField value={remember} label='Mantener sesion abierta' onChange={(e) => setRemember(e.target.checked)} />
                                <Link onClick={() => {/* recoveryModal function */}} variant="body2">
                                    {"¿Tienes problemas para ingresar? Recuperación de contraseña"}
                                </Link>
                                {!waiting ? (
                                    <ButtonField style={{ float: 'right' }} label='Inicia sesion' />
                                ) : (
                                    <div style={{ display: 'flex', justifyContent: 'center' }}>
                                        <CircularProgress color="inherit" size={22} />
                                    </div>
                                )}
                                <Grid container>
                                    <Grid item>
                                        <Link href="/cuentac" variant="body2">
                                            {"¿No tienes cuenta? Date de alta"}
                                        </Link>
                                    </Grid>
                                </Grid>
                            </form>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            {error && <AlertMessage open={error} message={message} onClose={() => setError(false)} severity="error" />}
        </div>
    );
};

export default Login;

import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';

import {
    Paper,
    Typography,
} from '@material-ui/core/';

const styles = theme => ({
    root: {
        paddingTop : theme.spacing(2),
        paddingLeft : theme.spacing(3),
        paddingRight : theme.spacing(3),
        paddingBottom : theme.spacing(2),

        marginTop : theme.spacing(1),
        marginBottom : theme.spacing(4),
    }
})

class Section extends Component {

    componentWillMount() {

    }

    render(){
        const { classes, title, children } = this.props;

        return (
            <Paper className={classes.root} >
            { title ? 
                <Typography variant="h6" className={classes.title}>
                    {title}
                </Typography>
            : null}
                {children}
            </Paper>
        )
    }
}

export default withStyles(styles)(Section);

//System
import React from 'react';

//Material core
import { withStyles } from '@material-ui/core/styles';

import {
    Dialog,
    TextField,
    DialogTitle,
    DialogActions,
    Button,
    DialogContent,
    DialogContentText,
    FormControl,
    Select,
    MenuItem,
    InputLabel,

} from '@material-ui/core/';

const styles = theme => ({

})

class ClientDialog extends React.Component {



    render() {
        const { classes, title, text, onOkClick, onCancel, status,  ...props } = this.props;

        return (
            <Dialog open={status} onClose={this.closeDialog} aria-labelledby="form-dialog-title" >
              <DialogTitle id="form-dialog-title">
                {title}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  {text}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={onCancel} color="primary">
                  No
                </Button>
                <Button onClick={onOkClick} color="primary">
                  Si
                </Button>
              </DialogActions>
            </Dialog>
        )
    }
}

export default withStyles(styles)(ClientDialog)
import React, { Component } from "react";

//Material core
import { withStyles } from '@material-ui/core/styles';

import {
    TextField,
} from '@material-ui/core/';

//import Layout from '../components/Layout';
//import { getToken, setToken } from '../functions.js';

const styles = theme => ({
    toolbar : {
        backgroundColor : theme.palette.primary.main
    }
})

class CustomTextField extends Component {

    componentWillMount() {

    }

    render(){
        const { classes, data, value, onChange, label, autoFocus, type, optional, multiline } = this.props;

        return (
            <TextField error={value || optional ? false : true} value={value} type={type} variant="standard" margin="normal" required={optional ? false : true} fullWidth id={value} label={label} name={value} autoComplete={value} onChange={onChange} autoFocus={autoFocus} multiline={multiline} />
        )
    }
}

export default withStyles(styles)(CustomTextField);

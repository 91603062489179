import React, { Component } from "react";

//Material core
import { withStyles } from '@material-ui/core/styles';

import {
    Typography,
} from '@material-ui/core/';

//import Layout from '../components/Layout';
//import { getToken, setToken } from '../functions.js';

const styles = theme => ({
    toolbar : {
        backgroundColor : theme.palette.primary.main
    }
})

class TextLabel extends Component {

    componentWillMount() {

    }

    render(){
        const { classes, label } = this.props;

        return (
            <Typography variant="h7" className={classes.title}>
                {label}
            </Typography>
        )
    }
}

export default withStyles(styles)(TextLabel);

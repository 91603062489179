import React, { Component } from "react";

//Material core
import { withStyles } from '@material-ui/core/styles';

import {
    FormControlLabel,
    Checkbox,
} from '@material-ui/core/';

//import Layout from '../components/Layout';
//import { getToken, setToken } from '../functions.js';

const styles = theme => ({
    toolbar : {
        backgroundColor : theme.palette.primary.main
    }
})

class CheckBoxField extends Component {

    componentWillMount() {

    }

    render(){
        const { classes, value, onChange, label } = this.props;

        return (
            <FormControlLabel control={<Checkbox color='primary' checked={value} onChange={onChange} />} label={label} />
        )
    }
}

export default withStyles(styles)(CheckBoxField);

import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";


import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    
    title:{
        color : '#eb6324',
        fontSize : 24,
        marginTop : 40
    }

})
var user = null;

class Aviso extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            

            <Grid container alignItems="center" justify="center" spacing={0}>
              

                <Container maxWidth='md'>
                <Grid  item>
                <img onClick={()=>{window.location="/"}} src="logofte.svg" style={{height:44, marginTop:30,cursor:'pointer'}}/>
                <div className={classes.title}>Aviso de privacidad</div>
                </Grid>

                <div >
    
          <div >

<p>        
El presente documento constituye el Aviso de Privacidad para efectos de lo dispuesto en La Ley Federal de Protección de Datos Personales en Posesión de los Particulares (en adelante “La Ley”), su Reglamento así como los Lineamientos del Aviso de Privacidad (en adelante “Lineamientos”), a efecto de garantizar el Derecho a la Autodeterminación Informativa, privacidad, respeto y obligaciones que tenemos con relación al uso adecuado de la información de aquellas personas que nos proporcionan datos personales.</p>

<b><p>1.- IDENTIDAD Y DOMICILIO. </p></b>


<p>Fobos Telecomunicaciones (en adelante Fobos) con domicilio para oír y recibir notificaciones en Circuito Paseo Tuna 18 local 217 int C, Fraccionamiento Zibatá, CP. 76269, El Marques, será el responsable del tratamiento de sus datos personales de conformidad con lo establecido en la “La Ley”.</p>

<b><p>2.-DATOS PERSONALES QUE SERAN SOMETIDOS A TRATAMIENTO. </p></b>

<p>Los Datos Personales que recabamos de usted con los fines descritos en el numeral 4 del presente Aviso de Privacidad, son recabados de manera personal cuando nos los proporciona a través de nuestras oficinas, de manera directa cuando ingresa sus datos a través de nuestro sitio web, a través de nuestro centro de atención telefónica, y de manera indirecta cuando otras empresas que pueden ser nuestros clientes o proveedores nos transfieren sus datos y cuando obtenemos información a través de las fuentes de acceso público permitidas por la “ Ley”. </p>

<p>Los datos personales de Identificación que recabamos son los siguientes:

 • Nombre. 
 • Domicilio. 
 • Correo electrónico. 
 • Teléfono.</p>

<b><p>3.-DATOS PERSONALES SENSIBLES. </p></b>

<p>Fobos NO RECABA DATOS PERSONALES DE CARÁCTER SENSIBLE solo recaba aquellos datos que son necesarios para poder contactarlo, para la relación comercial o jurídica.</p>

<b><p>4.-FINALIDADES DEL TRATAMIENTO Y/O RESGUARDO. </p></b>

<p>Fobos hace de su conocimiento que los Datos Personales proporcionados voluntariamente por usted, son recabados con 2 finalidades:</p>

<p>  1) PRIMARIAS.- Finalidades que dan origen y son necesarias para la existencia y cumplimiento de la obligación jurídica, es decir, Datos personales 
      indispensables para brindar nuestros servicios tales como:

    • Que usted pueda ponerse en contacto con nosotros o nosotros poder contactarlo a usted para ofrecerle nuestros servicios. 
    • Gestionar y proporcionarle los servicios solicitados. 
    • Atender las solicitudes de consulta y comentarios que usted nos deje por medio del sitio web. 
    • Que pueda ponerse en contacto con nosotros y dejarnos comentarios en el sitio web.</p>

<p>  2) SECUNDARIAS.- Finalidades distintas y que no dan origen a la existencia de la obligación jurídica, sin embargo son de suma importancia y utilidad 
      para poder brindarle y ofrecer nuestros servicio que podrán ser:

    • Evaluación y seguimiento de servicios prestados.
    • Para enviarle comunicados acerca de nuestros servicios.
    • Enviarle comunicados e informativas acerca de nuevos servicios que ofrecemos o que ofreceremos.</p>
<b><p>
5.- NEGATIVA AL TRATAMIENTO DE SUS DATOS PERSONALES CON FINALIDADES SECUNDARIAS.</p></b>

<p>Cuando obtengamos sus Datos Personales de manera indirecta, usted contará con un plazo de 5 (cinco) días hábiles contados a partir de la fecha que fueron otorgados para manifestar su negativa al tratamiento sus Datos Personales para finalidades secundarias señaladas en el numeral 4 (cuatro) inciso 2), del presente Aviso de Privacidad, para ello es necesario nos haga llegar una solicitud que deberá contener los requisitos señalados en el numeral 6 (seis) del presente Aviso de Privacidad, la cual deberá ser enviada a la dirección electrónica contacto@fte.mx haciendo mención de los Datos y finalidades secundarias que desea negarse a su tratamiento. En un plazo no mayor a 10 (diez) días hábiles contados a partir de la fecha de recepción de la solicitud, haremos efectiva la cancelación Notificándole al correo electrónico que usted haya señalado para cancelación de los mismos.</p>

<p>En caso de no manifestar su negativa al tratamiento de Datos Personales con las finalidades secundarias anteriormente descritas en el plazo de 5 (cinco) días hábiles, se le tendrá por consentido y aceptado el uso y tratamiento de sus Datos Personales para dichas finalidades; sin embargo, esto no será impedimento para que usted en todo momento pueda ejercitar sus Derechos ARCO que se describen en el numeral 6 del presente aviso de privacidad.</p>

<b><p>6.- SOLICITUD ARCO.</p></b>

<p>Es importante informarle que usted tiene en todo momento el derecho al Acceso, Rectificación y Cancelación de sus datos personales, a Oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado. El titular podrá ejercer estos derechos, por sí mismo o a través de su representante legal dirigiendo una solicitud a la siguiente dirección de correo electrónico protecciondedatos@Fobos.mx, que contenga al menos la siguiente información:</p>

  <p>• La descripción de manera clara y precisa de los datos personales respecto de los cuales busca ejercer sus derechos ARCO, así como el derecho o 
      derechos que desea ejercer, lo cual podrá hacer en el texto del correo electrónico o en un documento adjunto escaneado y debidamente firmado al final 
      del mismo y rubricado al calce de cada una de las hojas, o bien, firmados de manera electrónica.</p>
  <p>• Señalar expresamente su deseo de recibir nuestra respectiva contestación a su petición a través de correo electrónico, indicando la dirección de correo 
      electrónico que corresponda.</p>
  <p>• Copia de identificación oficial vigente del Titular y/o de su representante legal (en caso de hacerlo mediante representante legal se deberá adjuntar 
      escaneada la carta poder firmada ante 2 testigos). Los documentos podrán ser escaneados y adjuntados al correo electrónico para verificar su veracidad.</p>

<p>Una vez que su solicitud para ejercer sus derechos se encuentre a nuestra disposición, independientemente de la forma en que la recibamos, se emitirá la respectiva contestación en un plazo no mayor a 20 días hábiles a partir de su recepción. La cual haremos de su conocimiento a través del medio de contacto que nos solicite. En caso de estar inconforme podrá contactarnos de manera inmediata a través de los medios señalados, por lo que nos pondremos a su entera disposición de inmediato para abordar sus inquietudes, independientemente de hacer valer ante la autoridad competente los derechos que le correspondan. Si resulta procedente la solicitud de acceso, rectificación, cancelación u oposición, se hará efectiva la misma dentro de los 15 (quince) días hábiles siguientes a la fecha en que se comunique la respuesta. En caso de no responder nuestra contestación en el plazo señalado, nosotros Fobos entendemos de buena fe que está conforme con nuestra respuesta. Si su solicitud es acerca del ejercicio del derecho de Acceso, nosotros Fobos pondrá su información o datos personales a su disposición a través de copias simples y/o documentos electrónicos. En caso de omitir alguno de los requerimientos estipulados en los artículos citados con anterioridad, la solicitud se tendrá como no interpuesta y se le dará aviso de dicha omisión dentro de los 10 días siguientes a la solicitud vía correo electrónico.</p>

<b><p>7.- REVOCACIÓN DEL CONSENTIMIENTO AL TRATAMIENTO DE SUS DATOS PERSONALES.</p></b>

<p>En todo momento usted podrá revocar su consentimiento al tratamiento de sus Datos Personales, para ello es necesario nos haga llegar un correo electrónico a la siguiente dirección electrónica: protecciondedatos@fobos.mx, la cual deberá contener los requisitos señalados en el numeral 6 (seis) del presente Aviso de Privacidad haciendo un señalamiento de los Datos Personales a los cuales desea revocar su consentimiento. En un plazo no mayor a 20 (veinte) días hábiles contados a partir de la recepción de su correo, haremos la revocación de los datos solicitados.</p>

<b><p>8.-USO O DIVULGACIÓN DE LOS DATOS PERSONALES.</p></b>

<p>Fobos pone a su disposición la siguiente dirección de correo electrónico contacto@fte.mx para que usted Titular pueda manifestar en todo momento su negativa a seguir recibiendo comunicados o promociones por parte de nosotros, o bien, solicitándolo en nuestras oficinas ubicadas en la dirección arriba mencionada, dirigiéndose al área de protección de datos. Es necesario hacerle saber qué para restringir, limitar o controlar el tratamiento de sus datos personales Fobos cuenta con medidas de seguridad administrativas, físicas y técnicas, además de establecer políticas y programas internos de privacidad para evitar la divulgación de sus datos personales, implementando diversos controles de seguridad. Los datos personales de los cuales son titulares serán tratados de forma estrictamente confidencial, por lo que la obtención, tratamiento, transferencia y ejercicio de los derechos derivados de dichos datos personales, es mediante el uso adecuado, legítimo y licito, salvaguardando permanentemente los principios de licitud, consentimiento, calidad, información, proporcionalidad y responsabilidad.</p>

<b><p>9.-“USO DE “WEB BEACONS” Y COOKIES”.</p></b>

<p>Algunas partes del sitio web de www.fte.mx pueden utilizar "Cookies" y web beacons para simplificar la navegación. Las cookies son archivos de texto que son descargados automáticamente y almacenados en el disco duro del equipo de cómputo del usuario al navegar en una página de Internet específica, que permiten recordar al servidor de Internet algunos datos sobre este usuario, entre ellos, sus preferencias de compra para la visualización de las páginas en ese servidor, nombre y contraseña. Por su parte, las web beacons son imágenes insertadas en una página de Internet o correo electrónico, que puede ser utilizado para monitorear el comportamiento de un visitante, como almacenar información sobre la dirección IP del usuario, duración del tiempo de interacción en
dicha página y el tipo de navegador utilizado, entre otros.</p>

<p>Le informamos que utilizamos cookies y web beacons para obtener información personal de usted, como la siguiente: i) El tipo de navegador y sistema operativo que utiliza; ii) Las páginas de Internet que visita en forma previa y posterior a la entrada del Sitio Web de Fobos; iii) Los vínculos que sigue y permanencia en nuestro sitio; iv) Su dirección IP; v) Lugar desde el cual nos visita y estadísticas de navegación. Estas cookies y otras tecnologías pueden ser deshabilitadas. Puede buscar información sobre los navegadores conocidos y averiguar cómo ajustar las preferencias de las cookies en los siguientes sitios web:</p>

<p>Microsoft Internet Explorer:
http://www.microsoft.com/info/cookies.htm</p>

<p>Mozilla Firefox:
http://www.mozilla.org/projects/security/pki/psm/help_21/using_priv_help.html</p>

<p>En el caso de empleo de cookies, el botón de "ayuda" que se encuentra en la barra de herramientas de la mayoría de los navegadores, le dirá cómo evitar aceptar nuevas cookies, cómo hacer que el navegador le notifique cuando recibe una nueva cookie o cómo deshabilitar todas las cookies.</p>

<b><p>10.-TRANSFERENCIA DE DATOS PERSONALES.</p></b>

<p>Fobos podrá transferir sus datos en los siguientes casos:
FINALIDADES PRIMARIAS:
(i)esté prevista en una Ley o Tratado en los que México sea parte; (ii) sea efectuada a sociedades controladoras, subsidiarias o afiliadas
del responsable las cuales operan bajo las mismas políticas de privacidad (iii) sea necesaria por virtud de un contrato celebrado o por
celebrar en interés del titular; (iv) sea necesaria o legalmente exigida para la salvaguarda de un interés público, o para la procuración o
administración de justicia; (v) Autoridades locales o federales quienes lo hayan solicitado como parte de su proceso legal o en caso
específico por mandato judicial, en estos casos le serán informado previamente el tipo de información que es requerida y para los fines
que ha sido solicitada; (vi) sea precisa para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial; y, (vii) sea
precisa para el mantenimiento o cumplimiento de una relación jurídica con el titular.</p>
<p>
EN EL CASO DE FINALIDADES SECUNDARIAS
  • Empresas con actividades comerciales.- Con la finalidad de prospección comercial y venta de productos o servicios.
Si usted como titular de los datos no está de acuerdo con las transferencias que se describen en este apartado, podrá interponer su negativa enviándonos su solicitud a la dirección de correo electrónico: protecciondedatos@Fobos.mx</p>

<b><p>11.- CAMBIOS AL AVISO DE PRIVACIDAD.</p></b>

<p>Fobos se reserva el derecho de efectuar modificaciones o actualizaciones al presente aviso de privacidad para atender a novedades o actualizaciones legislativas, políticas internas, nuevos requerimientos, se publicará o actualizará el presente Aviso de Privacidad es por lo cual se le solicita al TITULAR que consulte de manera frecuente y a fin de mantenerse informado el sitio www.Fobos.mx sección aviso de privacidad con la finalidad de mantenerse actualizado y al tanto de los términos y condiciones vigentes así como en caso de que el titular no desee que sus datos sean transferidos o sea su deseo hacer uso de sus derechos ARCO deberá de notificarlo a la dirección de correo electrónico protecciondedatos@Fobos.mx o bien solicitándolo en nuestras oficinas ubicadas en la dirección arriba mencionada, dirigiéndose al área de protección de datos.</p>

<b><p>12.- EXCLUSIÓN DE RESPONSABILIDAD</p></b>

<p>Nuestro sitio web podría contener o hacer referencia a enlaces, hipervínculos o hipertextos “links”, banners, botones y/o herramientas de búsqueda en Internet que al ser utilizados por los usuarios transportan a otros portales o sitios de Internet que podrían ser propiedad de terceros. Fobos no controla dichos sitios ni se hace responsable por los avisos de privacidad que ostenten o la falta de ellos, los datos personales que los usuarios llegasen a proporcionar a través de estos portales o sitios de Internet distintos a nuestro sitio web son su responsabilidad, por lo que deberá verificar el aviso de privacidad en cada sitio al que acceda.</p>

<p>Algunos vínculos, banners y/o botones que solicitan datos personales dentro de nuestro sitio web son responsabilidad de terceros ajenos a Fobos, quienes en ocasiones son nuestros proveedores de servicios por lo que se rigen por sus propios términos y políticas de privacidad, para conocer más acerca de este tema lo invitamos a consultar nuestra sección términos y condiciones.</p>
          </div>
    </div>
           

                </Container>
                
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Aviso);
import { layout_config } from '../config.js';
import React, { useState, useContext, useEffect } from "react";
import { ThemeProvider } from "@material-ui/styles";
import { Container, CssBaseline, Box, Grid, Modal } from "@material-ui/core";
import { MenuRounded } from "@material-ui/icons";
import { Root } from "mui-layout";
import WebNavigator from './WebNavigator';
import WebFooter from './WebFooter';
import { home_sections } from '../config.js';
import TabsWeb from './TabsWeb';
import Hidden from '@material-ui/core/Hidden';
import { useLocation } from "react-router-dom";
import theme from '../theme.js';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Login from '../pages/login';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import { UserContext } from '../contexts/UserContext';
import Recuperacion from '../contexts/Webpages/Recuperacion';

const WebLayout = (props) => {
    const location = useLocation(); // useLocation hook to get location data
    const currentDomain = window.location.hostname;
    const [open, setOpen] = useState(false);
    const [modalLogin, setModalLogin] = useState(false);
    const [modalRecovery, setModalRecovery] = useState(false);
    const [textoLog, setTextoLog] = useState("Mi FTE");
    const userContext = useContext(UserContext);

    useEffect(() => {
        userContext.login((res) => {
            if (res) {
                const namer = res.nombre || "";
                setTextoLog('Bienvenido ' + namer);
            } else {
                setTextoLog('Mi FTE');
            }
        });
    }, [userContext]);

    const goLogin = () => {
        if (userContext.user) {
            window.location = currentDomain == "mi.fte.mx" ? "/dash" : "https://mi.fte.mx/dash";
        } else {
            setModalLogin(true);
        }
    };

    const toggleDrawer = () => {
        setOpen(!open);
    };

    const handleCloseModal = () => {
        setModalLogin(false);
    };

    const handleRecoveryModal = () => {
        setModalRecovery(false);
        setModalLogin(false);
    };

    const openRecovery = () => {
        setModalRecovery(true);
    };

    const config = {
        ...layout_config,
        navWidth: 256,
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Root config={config}>
                <div style={{ backgroundColor: '#fff', scrollX: 'hidden' }}>
                    <Container style={{ paddingBottom: 0, paddingRight: 20, paddingLeft: 20 }} maxWidth="lg">
                        <Grid container alignContent="center" justify="center" style={{ flex: 1, flexDirection: 'row' }}>
                            <WebNavigator open={open} retf={toggleDrawer} mifte={goLogin} />
                            
                            {/* Login Modal */}
                            <Modal
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                aria-labelledby="login"
                                aria-describedby="credenciales"
                                className={theme.modal}
                                open={modalLogin}
                                onClose={handleCloseModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{ timeout: 500 }}
                            >
                                <Fade in={modalLogin}>
                                    <div className={theme.paper}>
                                        <Login recoveryModal={openRecovery} />
                                    </div>
                                </Fade>
                            </Modal>

                            {/* Recovery Modal */}
                            <Modal
                                style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                                aria-labelledby="login"
                                aria-describedby="recovery"
                                className={theme.modal}
                                open={modalRecovery}
                                onClose={handleRecoveryModal}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{ timeout: 500 }}
                            >
                                <Fade in={modalRecovery}>
                                    <div className={theme.paper}>
                                        <Recuperacion closeHandle={handleRecoveryModal} />
                                    </div>
                                </Fade>
                            </Modal>

                            <Grid item style={{ alignSelf: 'flex-start' }} xs={1}>
                                <Hidden mdUp>
                                    <MenuRounded onClick={toggleDrawer} style={{ fill: "#000", marginRight: 20, cursor: 'pointer' }} />
                                </Hidden>
                            </Grid>

                            <Grid container alignContent="center" justify="center" style={{ flex: 1, flexDirection: 'row' }}>
                                <Grid item lg={12} md={12} style={{ flexDirection: 'row' }}>
                                    <img src="logofte.svg" style={{ height: 44, marginTop: 0 }} alt="Logo" />
                                </Grid>
                            </Grid>

                            <Hidden smDown>
                                <Grid item md={7} sm={12}>
                                    <TabsWeb tab={props.tab} tabs={home_sections} />
                                </Grid>
                            </Hidden>

                            <Hidden smDown>
                                <Grid onClick={goLogin} item md={2} xs={6}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'flex-end',
                                        flexWrap: 'wrap',
                                        marginTop: 12,
                                        cursor: 'pointer',
                                        padding: 2,
                                        borderRadius: 4,
                                        fontWeight: 'bold',
                                    }}>
                                        {textoLog} <AssignmentIndIcon style={{ fill: "#eb6324", marginLeft: 10 }} />
                                    </div>
                                </Grid>
                            </Hidden>
                        </Grid>
                    </Container>

                    <Container style={{ paddingTop: 0 }}>
                        {/* Render children */}
                        {props.children}
                    </Container>

                    <WebFooter />
                </div>
            </Root>
        </ThemeProvider>
    );
};

export default WebLayout;

import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Updated import
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ExitToApp } from "@material-ui/icons";

import {
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core/';

import {
    Home as HomeIcon,
    AccountBox as AccountBoxIcon,
} from '@material-ui/icons/';

import { withStyles } from '@material-ui/core/styles';

const categories = [
    {
        id: 'Catalogo',
        sections: [
            { id: 'Nodo', icon: <AccountBoxIcon />, url: '/nodo' },
        ],
    },
];

const styles = theme => ({
    categoryHeader: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    categoryHeaderPrimary: {
        color: theme.palette.common.white,
    },
    item: {
        paddingTop: 1,
        paddingBottom: 1,
        color: 'rgba(255, 255, 255, 0.7)',
        '&:hover,&:focus': {
            backgroundColor: 'rgba(255, 255, 255, 0.08)',
        },
    },
    itemCategory: {
        backgroundColor: '#232f3e',
        boxShadow: '0 -1px 0 #404854 inset',
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    firebase: {
        fontSize: 24,
        color: theme.palette.common.white,
    },
    itemActiveItem: {
        color: '#4fc3f7',
    },
    itemPrimary: {
        fontSize: 'inherit',
    },
    itemIcon: {
        minWidth: 'auto',
        marginRight: theme.spacing(2),
    },
    divider: {
        marginTop: theme.spacing(2),
    },
    navlink: {
        color: theme.palette.common.white,
        textDecoration: 'none',
    },
});

function AppNavigator(props) {
    const { classes } = props;
    const navigate = useNavigate(); // Hook for navigation

    const navigateTo = (link) => {
        if (window.location.pathname !== link) {
            navigate(link);
        }
    };

    return (
        <div>
            <List disablePadding>
                <ListItem className={clsx(classes.firebase, classes.item, classes.itemCategory)}>
                    FTE
                </ListItem>
                <ListItem button className={clsx(classes.item, classes.itemCategory, (window.location.pathname === '/') && classes.itemActiveItem)} component={Link} to="/">
                    <ListItemIcon className={classes.itemIcon}>
                        <HomeIcon />
                    </ListItemIcon>
                    <ListItemText classes={{ primary: classes.itemPrimary }}>
                        Inicio
                    </ListItemText>
                </ListItem>
                {categories.map(({ id, sections }) => (
                    <React.Fragment key={id}>
                        <ListItem className={classes.categoryHeader}>
                            <ListItemText
                                classes={{
                                    primary: classes.categoryHeaderPrimary,
                                }}
                            >
                                {id}
                            </ListItemText>
                        </ListItem>
                        {sections.map(({ id, icon, url }) => (
                            <ListItem
                                key={id}
                                button
                                className={clsx(classes.item, (window.location.pathname === url) && classes.itemActiveItem)}
                                component={Link} to={url}
                            >
                                <ListItemIcon className={classes.itemIcon}>{icon}</ListItemIcon>
                                <ListItemText
                                    classes={{
                                        primary: classes.itemPrimary,
                                    }}
                                >
                                    {id}
                                </ListItemText>
                            </ListItem>
                        ))}
                        <Divider className={classes.divider} />
                    </React.Fragment>
                ))}
            </List>
        </div>
    );
}

export default withStyles(styles)(AppNavigator);

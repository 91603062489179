import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";


import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    
    title:{
        color : '#eb6324',
        fontSize : 24,
        marginTop : 40
    }

})
var user = null;

class Gestion extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            

            <Grid container alignItems="center" justify="center" spacing={0}>
              

                <Container maxWidth='md'>
                <Grid  item>
                <img onClick={()=>{window.location="/"}} src="logofte.svg" style={{height:44, marginTop:30,cursor:'pointer'}}/>
                <div className={classes.title}>Política de Gestión de Tráfico y Administración de Red </div>
                </Grid>

                <div >
    
          <div >

<p>        
La Política de Gestión de Tráfico y Administración de Red de Fobos Telecomunicaciones S.A. de C.V. (“FTE”) tiene como propósito asegurar la calidad, capacidad y velocidad del servicio de acceso a Internet y preservar la integridad y seguridad de la red, de conformidad con lo establecido en el Artículo 3 de los “Lineamientos para la gestión de tráfico y administración de red a que deberán sujetarse los concesionarios y autorizados que presten el servicio de acceso a Internet”. </p>

<p>Fobos Telecomunicaciones S.A. de C.V. (“FTE”) ofrece el servicio de acceso a Internet al Cliente utilizando sus plataformas para entregar el tráfico de datos a la red pública de Internet, incluye el servicio de DNS, Firewall y CG-NAT (Network Address Translation) que permite la traducción de IPs privadas a IPs públicas en Internet, así como el ISP para que el Cliente cuente con navegación en Internet. </p>
<p>El tráfico de datos sobre la cobertura ofrecida por Fobos Telecomunicaciones S.A. de C.V. (“FTE”) opera bajo esquema best effort, toda vez que la red no garantiza la calidad del servicio (QoS) del tráfico. </p>

<b><p>Derechos de los usuarios finales del servicio de acceso a Internet.  </p></b>
<b>1. Libre eleccion</b>
<p>El servicio de acceso a Internet que ofrece Fobos Telecomunicaciones S.A. de C.V. (“FTE”) permite que los usuarios finales de los clientes de Altán puedan acceder a cualquier contenido, aplicación o servicios en Internet, sin dificultar, limitar, degradar, restringir o discriminar el acceso a los mismos.  </p>

<b>2. No discriminatorio</b>
<p>Fobos Telecomunicaciones S.A. de C.V. (“FTE”) se obliga a tratar de la misma manera el tráfico de los contenidos, aplicaciones o servicios de tipo similar en Internet entre los usuarios finales de los Clientes de Fobos Telecomunicaciones S.A. de C.V. (“FTE”). </p>

<b>3. Privacidad y seguridad de las comunicaciones. </b>
<p>A nivel técnico, Fobos Telecomunicaciones S.A. de C.V. (“FTE”) se encuentra obligado a asegurar la inviolabilidad de las comunicaciones privadas de los usuarios finales de los Clientes a través de la red de Fobos Telecomunicaciones S.A. de C.V. (“FTE”) y su privacidad. Fobos Telecomunicaciones S.A. de C.V. (“FTE”) no utiliza las técnicas de DPI/DFI para monitoreo de tráfico. </p>

<b>4. Gestión de tráfico basada en volumen de datos con una vigencia determinada. </b>
<p>Consiste en ofrecer a los clientes un volumen de datos con una vigencia determinada a velocidad best effort, una vez alcanzado los datos del producto contratado para un usuario final, el cliente puede contratar un nuevo producto y/o contratar un producto de consumo excedente que incluye un volumen de datos a velocidad reducida de 512 kbps.  

De no llevar a cabo esta práctica, se podría saturar la red y poner en riesgo el cumplimiento de los términos y condiciones de calidad de las ofertas de referencia. </p>

<b>5. Calidad y Gestión de congestión. </b>
<p>Fobos Telecomunicaciones S.A. de C.V. (“FTE”) para garantizar su servicio ofrece a sus clientes una tasa de transmisión descendente de al menos 4 Mbps y una tasa de transmisión ascendente de al menos 1 Mbps en el borde de la cobertura exterior en hora pico. </p>
<p>La calidad de los servicios puede verse afectada por una mayor demanda de tráfico, por lo que Fobos Telecomunicaciones S.A. de C.V. (“FTE”) podrá́ suspender las activaciones en una determinada región o localidad, sin responsabilidad alguna para Fobos Telecomunicaciones S.A. de C.V. (“FTE”). 

Fobos Telecomunicaciones S.A. de C.V. (“FTE”) ajustará los parámetros técnicos en el servicio de Internet al hogar, por lo que puede implementar una reducción de velocidad de hasta 2.5Mbps en hora pico y sitios saturados. Aplica en caso de un incremento significativo en la demanda de tráfico y/o usuarios finales en un determinado eNB/sector. La reducción de velocidad aplica para todo el tráfico de datos, por lo que de no implementarla podría afectar la operación de la red y a la calidad de los servicios ofrecidos.  </p>

<b>6. Bloqueo. </b>
<p>Fobos Telecomunicaciones S.A. de C.V. (“FTE”) no lleva a cabo el bloqueo de tráfico de datos en los servicios de movilidad e Internet al hogar que tengan contratados sus Clientes. </p>


<b>7. Priorización pagada. </b>
<p>Fobos Telecomunicaciones S.A. de C.V. (“FTE”) no ofrece el servicio de priorización pagada y no cuenta con una oferta de referencia para tal efecto. </p>

<b>8. Datos patrocinados</b>
<p>Fobos Telecomunicaciones S.A. de C.V. (“FTE”) ofrece datos patrocinados en los siguientes casos a los clientes que tengan contratadas las ofertas de referencia en las que Fobos Telecomunicaciones S.A. de C.V. (“FTE”) presta el servicio de acceso a Internet: 

Acceso gratuito a la URL http://educacionconequidad.sep.gob.mx (con IP 168.255.101.55) para el programa “Aprende en Casa”. Aplica cuando el usuario esté usando el servicio en algunos de los nodos-celdas determinadas conforme a las zonas solicitadas por el gobierno, en un horario de lunes a viernes de 8 A.M. a 4 P.M. con velocidad reducida de hasta 300 kbps. </p>

<p>Acceso gratuito a las siguientes URLs definidas para COVID con velocidad reducida de hasta 300 kbps. </p>

<ul>
<li>https://coronavirus.gob.mx </li>

<li>alfa.gob.mx </li>

<li>servicios.gob.mx </li>

<li>api.gob.mx </li>

<li>http://aprendeencasa.sep.gob.mx </li>

<li>http://www.aprende.edu.mx </li>
<li>https://telesecundaria.sep.gob.mx o https://educacionbasica.sep.gob.mx </li>

<li>URLs del Cliente para realizar la activación del producto en usuarios finales, en atención a usuarios sin costo, así́ como portales de pagos a velocidad de hasta 128Kbps. </li>

<li>URLs del Cliente con texto plano para gestión de usuarios finales.  </li>
</ul>

<b>Glosario</b>
<ul>
    <li>CG-NAT: Carrier Grade Network Address Translation. </li>

    <li>Cliente: Concesionario de servicios de telecomunicaciones o comercializadora, que celebra un contrato con Fobos Telecomunicaciones S.A. de C.V. (“FTE”). </li>

    <li>Core: capa de red encargada de proporcionar conectividad entre los distintos puntos de acceso. </li>

    <li>DNS: Domain Name System  </li>

    <li>eNB: Evolved Node B </li>

    <li>IFT: Instituto Federal de Telecomunicaciones. </li>

    <li>ISP: Service Provider  </li>

    <li>URL: Uniform Resource Locator </li>
</ul>

<br></br>
<br></br>
<br></br>



          </div>
    </div>
           

                </Container>
                
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Gestion);
import React, { Component } from "react";

//Material core
import { withStyles } from '@material-ui/core/styles';

import {
    Button,
} from '@material-ui/core/';

//import Layout from '../components/Layout';
//import { getToken, setToken } from '../functions.js';

const styles = theme => ({
    button : {
        marginTop : theme.spacing(1),
        marginBottom : theme.spacing(1),
       
    }
})

class ButtonField extends Component {

    componentWillMount() {

    }

    render(){
        const { classes, onSubmit, label, disabled, onClick } = this.props;

        return (
            <Button type="submit"  variant="contained" color="primary" onClick={onClick} onSubmit={onSubmit} className={classes.button} >
              {label}
            </Button>
        )
    }
}

export default withStyles(styles)(ButtonField);

import React from "react";
import { Link, useNavigate } from "react-router-dom"; // Updated import

import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';

import { withStyles } from '@material-ui/core/styles';
import { Grid, Container } from "@material-ui/core";

const styles = theme => ({
    root: {
        padding: 20
    },
    footerCont: {
        color: '#939fa9',
        backgroundColor: '#1b3c65',
        height: 'auto'
    },
    footerTitle: {
        color: '#fff',
        fontSize: 22,
        marginBottom: 12
    },
    footerOrange: {
        color: theme.palette.orange.main,
        fontWeight: 'bold',
        cursor: 'pointer'
    },
    link: {
        cursor: 'pointer',
        '&:hover': {
            color: theme.palette.orange.main
        }
    }
});

function WebFooter(props) {
    const { classes } = props;
    const navigate = useNavigate(); // Hook for navigation

    return (
        <Grid className={classes.footerCont} container spacing={2} style={{ flex: 1, flexDirection: 'row' }}>
            <Container className={classes.root} maxWidth="md">
                <Grid container style={{ flex: 1, flexDirection: 'row', marginTop: 30, marginBottom: 30 }}>
                    <Grid item md={4} xs={12}>
                        <div className={classes.footerTitle}>Contáctanos</div>
                        <Grid item style={{ flex: 1, flexDirection: 'row', width: '100%', marginBottom: 30 }}>
                            <div style={{ float: 'left', marginRight: 10, width: '100%' }}>Teléfono:</div>
                            <div className={classes.footerOrange} onClick={() => { window.open('tel:+52 4426270000', '_blank') }}>
                                (+52) 44 26 27 0000
                            </div>
                            <div style={{ float: 'left', marginRight: 10, width: '100%' }}>E-mail:</div>
                            <div className={classes.footerOrange} onClick={() => { window.open('mailto:hola@fte.mx', '_blank') }}>
                                hola@fte.mx
                            </div>
                        </Grid>
                    </Grid>

                    <Grid item md={5} xs={12}>
                        <Grid container style={{ flexDirection: 'row', marginBottom: 30 }}>
                            <Grid item xs={6}>
                                <p onClick={() => { window.location = '#inicio' }} className={classes.link}>Inicio</p>
                                <p onClick={() => { window.location = '#telefonia' }} className={classes.link}>Telefonía</p>
                            </Grid>
                            <Grid item xs={6}>
                                <p onClick={() => { window.location = '#internet' }} className={classes.link}>Internet</p>
                                <p onClick={() => { window.location = '#cobertura' }} className={classes.link}>Cobertura</p>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={3} xs={12}>
                        <div className={classes.footerTitle}>Síguenos</div>
                        <FacebookIcon onClick={() => { window.open('https://www.facebook.com/FTEred', '_blank') }} className={classes.link} style={{ marginRight: 12, fontSize: 32 }} />
                        <TwitterIcon onClick={() => { window.open('https://twitter.com/fte_red', '_blank') }} className={classes.link} style={{ marginRight: 12, fontSize: 32 }} />
                    </Grid>
                </Grid>

                <Grid container style={{ flex: 1, flexDirection: 'row', borderTop: '.1px solid #b7b7b7', marginTop: 30, marginBottom: 30 }}>
                    <Grid item md={4} sm={12}>
                        <p className={classes.link} onClick={() => { navigate('/aviso') }}>Aviso de privacidad</p>
                        <p className={classes.link} onClick={() => { navigate('/terminos') }}>Términos y condiciones</p>
                    </Grid>

                    <Grid item md={4} sm={12}>
                        <p className={classes.link} onClick={() => { navigate('/carta') }}>Carta mínima de derechos</p>
                        <p className={classes.link} onClick={() => { navigate('/colaboracion') }}>Colaboración con la justicia</p>
                    </Grid>

                    <Grid item md={4} sm={12}>
                        <p className={classes.link} onClick={() => { navigate('/usojusto') }}>Política de uso justo</p>
                        <p className={classes.link} onClick={() => { navigate('/gestion') }}>Política de gestión de tráfico</p>
                    </Grid>

                    <Grid item xs={12}>
                        <p style={{ textAlign: 'center', marginTop: 80 }}>Fobos Telecomunicaciones S.A. de C.V.</p>
                    </Grid>
                </Grid>
            </Container>
        </Grid>
    );
}

export default withStyles(styles)(WebFooter);

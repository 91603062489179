import React, {useContext, List, useState} from "react";
import {  withStyles } from '@material-ui/core/styles';

import { getToken, setToken, getData } from '../functions.js';
import  { UserContext }  from '../contexts/UserContext';
import { d_saveCard} from './dataController';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, Tabs, Tab, Modal } from "@material-ui/core";
import InputMask from 'react-input-mask';
import MaskedInput from 'react-text-mask';
import RecargaItem from "./RecargaItem";
import AlertMessage from './fields/AlertMessage';
import ButtonField from '../components/fields/ButtonField';
import TextField from '../components/fields/TextField';


import Section from '../components/Section';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../theme.js';


const styles = theme => ({
    root: {
        flexGrow: 1,
      },
     
})

var user = null;


const openId = null;

class AddCardConekta extends React.Component {

    state ={
        validating : false
    } 

    

    static contextType = UserContext;

    tokenizeCard = () =>{

        let number = String(this.state.number).replaceAll("-","")

      // window.Conekta.setPublicKey("key_DJv99zngA2LhCULfcPkyzpQ"); // DEV
       window.Conekta.setPublicKey("key_VN336rKuDhcrsMcs8mvAbyg"); //PROD
        window.Conekta.setLanguage("es");  

        let data = {card :{
            "number":number,
            "name":this.state.name,
            "exp_year":this.state.yearexp,
            "exp_month":this.state.monthexp,
            "cvc":this.state.ccv
        }}

        this.setState({validating:true},()=>{
            window.Conekta.Token.create(data, (ret)=>{this.tokenSuccess(ret,data) } , (ret)=>{this.tokenError(ret,data)} );
        })
    }

    createAlertDiv(msg){
        this.setState({validating:false})
        alert(msg)
    }

    tokenSuccess = (res,data) =>{
        //let device_session_id = window.deviceSessionId
        console.log(res,"RESGRESO DE TOKEN", data)
       //this.saveCard({token_id:res.data.id,device_session_id:device_session_id,clientId:this.props.clientId}).then(ret =>{
           

           var data = [data.card]
           data[0].id = res.id;
           
           this.props.refreshC(data)
      // })
       
    }

    tokenError = (res,data) =>{


        var msg = this.createAlertDiv(res.message_to_purchaser);

        /*
        console.log(res,"ERRR")
        var http_code = res.data.http_code;
        var error_code = res.data.error_code;
    
        if(http_code != undefined){
            switch(error_code){
                case 3001:
                    var msg = this.createAlertDiv("La tarjeta fue rechazada.");
                    break;
                case 3002:
                    var msg = this.createAlertDiv("La tarjeta ha expirado.");
                    break;     
                case 3003:
                    var msg = this.createAlertDiv("La tarjeta no tiene fondos suficientes.");
                    break;    
                case 3004:
                    var msg = this.createAlertDiv("La tarjeta ha sido identificada como una tarjeta robada.");
                    break;
                case 3005:
                    var msg = this.createAlertDiv("La tarjeta ha sido rechazada por el sistema antifraudes.");
                    break;
                case 1000:
                    var msg = this.createAlertDiv("Error en el gateway de pago intente mas tarde.");
                    break;
                case 1001: 
                    var msg = this.createAlertDiv("Verifique los datos de la tarjeta.");
                    break;
                case 1003:
                    var msg = this.createAlertDiv("Promocion invalida para el tipo de tarjeta.");
                    break;
                case 2005:
                    var msg = this.createAlertDiv("La fecha de expiración ya no es válida");
                    break;
                case 2004:
                    var msg = this.createAlertDiv("El dígito de verificación es inválido");
                    break;
                default:
                    var msg = this.createAlertDiv("Error al registrar el pago con la tarjeta seleccionada. " + error_code);
                    break;
            }
        }*/
    }

    async saveCard(data){
        await d_saveCard(data).then( res =>{
           console.log(res)
           if(res.error_code){
                this.tokenError({data:res})
           }else{

           }
        })
      }


    render() {
        const { classes, history, session } = this.props;
        
        user = this.context.user;
      
        return (

                <Section  title='Agregar Tarjeta de crédito o débito' >

                 {!this.state.validating ? (
                     <div>
                     <Grid container spacing={2}>
                     <Grid item  xs={12}   >
                     <MaskedInput
                     style={{fontSize:18,border:'0px', textAlign:'left', width:'100%',borderBottom:'1px solid ', marginTop:20, 'textTransform': 'uppercase'}}
                            mask={[/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/,/[A-Za-zñÑ ]/]}   
                            className={classes.textInput}
                            placeholder="Nombre del Titular"
                            guide={false}
                            id="nombre"
                            
                            onBlur={() => {}}
                            onChange={(e) => this .setState({name:e.target.value})} 
                        />
                     </Grid>

                     <Grid item   xs={12}>


                     <MaskedInput
                            mask={[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            alwaysShowMask= {true}
                            style={{fontSize:18,border:'0px',width:'100%', textAlign:'left',borderBottom:'1px solid ', 'textTransform': 'uppercase'}}
                            placeholder="Número de la tarjeta"
                            type = {'numeric'}
                            guide={false}
                            id="tarjeta"
                            onBlur={() => {}}
                            onChange={(e) => this .setState({ number:e.target.value })} 
                        />


                  
                         
                     </Grid>
                     <Grid item xs={4} >
                    
                     <MaskedInput
                            mask={[/\d/, /\d/]}
                            
                            style={{fontSize:18,border:'0px', textAlign:'left', width:'100%',borderBottom:'1px solid '}}
                            placeholder="MES"
                            guide={false}
                            id="month"
                            type = {'numeric'}
                            onBlur={() => {}}
                            onChange={(e) => this .setState({ monthexp:e.target.value })} 
                        />

                       

                         
                     </Grid>
                     <Grid item xs={4} >
                     <MaskedInput
                            mask={[/\d/, /\d/]}
                            
                            style={{fontSize:18,border:'0px', textAlign:'left', width:'100%',borderBottom:'1px solid '}}
                            placeholder="AÑO"
                            guide={false}
                            id="month"
                            type = {'numeric'}
                            onBlur={() => {}}
                            onChange={(e) => this .setState({ yearexp:e.target.value })} 
                        />
                     </Grid>
                     <Grid item xs={4} >
                     <MaskedInput
                            mask={[/\d/, /\d/, /\d/]}
                            
                            style={{fontSize:18,border:'0px', textAlign:'left', width:'100%',borderBottom:'1px solid '}}
                            placeholder="CCV"
                            guide={false}
                            id="month"
                            type = {'numeric'}
                            onBlur={() => {}}
                            onChange={(e) => this .setState({ ccv:e.target.value })} 
                        />
                     </Grid>
 
                     <Grid item xs={12} style={{marginBottom:20}}>
                     
                    {/* <img src="openpay_color.png" style={{width:150,marginRight:20}} /> */}  
                     <img src="visa.png" style={{width:100, marginRight:20}} />
                     <img src="masterCard.png"  style={{width:50}} />
                     </Grid>

                    </Grid>  
                     <ButtonField onClick={this.tokenizeCard} label='Guardar Tarjeta' />
                     </div>
                 ) : (
                    <div style={{display:'flex',alignItems:'center',justifyContent:'center', margin:20}}>
                         <CircularProgress color="inherit" />
                    </div>

                 )}   
                
                   


            </Section>

        )


    }


}   



export default withStyles(styles)(AddCardConekta);
import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import { getToken, setToken, getData } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo, readOfferInfo } from './dataController';

import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import AlertMessage from './fields/AlertMessage';


import 'react-circular-progressbar/dist/styles.css';

import theme from '../theme.js';

const styles = theme => ({
    cuadroC :{
        marginTop : 60,
        marginBottom : 60
    },
    cuadroT : {
        
        height : 240,
        background : 'linear-gradient(136deg, rgb(251 150 50) 0%, rgba(238,100,38,1) 100%)',
        borderRadius : 4,
        marginRight : 40,
        marginLeft: 40,
        padding : 20
    },
    cuadroBt:{
        marginTop : 30,
        textAlign : 'center',
        padding : 5,
        backgroundColor : '#1a3a63',
        borderRadius : 4,
        color : '#fff',
        alignItems : 'flex-start',
        justify : 'center',
        marginRight :20,
        marginLeft : 20,
        fontWeight : 'bold',
        cursor : 'pointer',
        verticalAlign:'middle'
    },
    cuadroTitle:{
        fontSize: 30,
        fontWeight : 'bold',
        color : '#fff',
        textAlign: 'center',
        marginBottom : 40
    },
    cuadroDesc : {
        fontSize: 16,
        color : '#fff',
        textAlign: 'center'
    }
})
var user = null;

class HomeContent extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    static contextType = UserContext;

    componentDidMount(){
        this.setState({'waiting':true})
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };

    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
        window.location = "/dash#tienda"
    }

    alform = () =>{
       this.props.afif(true)
    }


    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            
<div>
            <Grid container alignItems="center" justify="center" spacing={0}>
                <Grid  item md={12}>
                    <img style={{width:'100%', borderRadius:'5px'}} src="/slider/slide1.png" />
                </Grid>

{/*
                <Grid  container alignItems="center" justify="center" style={{flex:1,flexDirection:'row',backgroundImage:'url(backrayas.png)', backgroundRepeat: 'space',backgroundPositionX: '30%'}}  >
                    <Grid item xs={12} sm={4} >
                        <div className={classes.cuadroC}>
                            <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>Telefonía Móvil</div>
                                <div className={classes.cuadroDesc}>¡No lo pienses más!</div>
                                <div className={classes.cuadroDesc}>Únete a la RED FTE y ahorra </div>
                                <div className={classes.cuadroDesc}>navegando en la mejor red. </div>
                                <div onClick={() => {window.location='/#telefonia'}} className={classes.cuadroBt}>más información</div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sm={4} >
                        <div className={classes.cuadroC}>
                            <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>Internet para casa</div>
                                <div className={classes.cuadroDesc}>Conoce nuestros planes </div>
                                <div className={classes.cuadroDesc}>de internet para casa</div>
                                <div className={classes.cuadroDesc}>al mejor precio y velocidad</div>
                                <div onClick={() => {window.location='/#telefoniafija'}} className={classes.cuadroBt}>más información</div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item  xs={12} sm={4} >
                        <div className={classes.cuadroC}>
                            <div className={classes.cuadroT}>
                                <div className={classes.cuadroTitle}>Telefónia Fija (IP)</div>
                                <div className={classes.cuadroDesc}>Telefonía fija para tu casa o</div>
                                <div className={classes.cuadroDesc}>negocio desde cualquiera lugar por </div>
                                <div className={classes.cuadroDesc}>medio de una conexión a internet</div>
                                <div onClick={() => {window.location='/#internet'}} className={classes.cuadroBt}>más información</div>
                            </div>
                        </div>
                    </Grid>
                </Grid>

      */}         


            </Grid>

            <Grid container>
                <Grid style={{marginTop:50}} item sm={12} md={6}>
                <Grid container style={{textAlign:'center', flex: 1, justifyContent: 'center', alignItems: 'center'}}>
                    <Grid  style={{marginTop:10,alignSelf:'center', fontSize:30,color:'#ff8000',fontWeight:'bold'}} item sm={12} >Cámbiate a la RED FTE</Grid>
                    <Grid  style={{marginTop:10,alignSelf:'center', fontSize:22,color:'#003471'}} item sm={12} >Contrata una línea nueva <br/> o conserva tu mismo número</Grid>
                    <Grid  style={{marginTop:10,alignSelf:'center', fontSize:22,color:'#ff8000', fontWeight:'bold'}} item sm={12} >¡Es muy facil!</Grid>
                    <div  onClick={() => {this.alform()}} style={{cursor:'pointer',marginTop:10,alignSelf:'center', fontSize:16,color:'#ff8000', backgroundColor:'#003471',borderRadius:8, color:'#fff', padding:5,paddingLeft:20,paddingRight:20,width:'auto',textAlign:'center',width:'230'}} item sm={12} >Quiero ser RED FTE</div>

                </Grid>
                </Grid>

                <Grid  item sm={12} md={6}>
                <img onClick={() => {this.alform()}} style={{marginTop:60,marginBottom:60,cursor:'pointer', margin:'0px auto', textAlign:'center',maxWidth: '100%'}} src="/banner2.jpg" />
                </Grid> 
            </Grid>
</div>

        )
    }

}


export default withStyles(styles)(HomeContent);
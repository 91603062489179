import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";


import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    
    title:{
        color : '#eb6324',
        fontSize : 24,
        marginTop : 40
    }

})
var user = null;

class Carta extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            

            <Grid container alignItems="center" justify="center" spacing={0}>
              

                <Container maxWidth='md'>
                <Grid  item>
                <img onClick={()=>{window.location="/"}} src="logofte.svg" style={{height:44, marginTop:30,cursor:'pointer'}}/>
                <div className={classes.title}>CARTA DE DERECHOS MÍNIMOS DE LOS USUARIOS DE LOS SERVICIOS 
PÚBLICOS DE TELECOMUNICACIONES
</div>
                </Grid>

                <div >
    
          <div >

<p>        
COMO USUARIO DE LOS SERVICIOS DE TELECOMUNICACIONES  ESTOS SON TUS PRINCIPALES DERECHOS: </p>

<b><p>1. Libre elección. </p></b>


<p>TÚ TIENES DERECHO a elegir libremente el servicio o servicios que quieras contratar, el proveedor que te lo proporcionará, el equipo o dispositivo que utilizarás (ejemplo celular, televisión, tableta, etc.), el plan y medio de pago que tendrás. </p>
<p>La información, promoción y/o publicidad deben ser oportunas, completas, claras, comprobables, veraces, y exentas de textos, diálogos, sonidos, imágenes, marcas, denominaciones de origen y otras descripciones que te induzcan o puedan inducirte a error o confusión por engañosas o abusivas, de manera que siempre puedas elegir con pleno conocimiento. En todo caso, el proveedor está obligado a cumplir lo que haya ofrecido a través de cualquier tipo de información, promoción o publicidad emitida.  </p>

<b><p>2. Servicios adicionales. </p></b>

<p>TÚ TIENES DERECHO a elegir el o los servicios que desees contratar, sin que el proveedor te condicione o te obligue a contratar servicios o productos adicionales al principal o en paquete.  </p>
<p>Tienes derecho a que el proveedor te informe los servicios o productos adicionales, especiales o conexos al servicio básico y a elegir libremente respecto de la contratación de los mismos. Asimismo, puedes dar por terminada la prestación de dichos servicios o productos adicionales, sin que ello implique la suspensión o cancelación de la prestación del servicio básico. </p>


<b><p>3.-DERECHO A LA PORTABILIDAD Y EL DESBLOQUEO. </p></b>

<p>TÚ TIENES DERECHO a cambiarte gratuitamente de compañía conservando tu mismo número telefónico, tantas veces como lo decidas, acudiendo con la nueva compañía con la que deseas contratar, quien deberá realizar la portabilidad durante las 24 horas siguientes a tu solicitud, o si así lo deseas, tienes derecho a decidir sobre la fecha en que quieres que se ejecute la portabilidad, siempre y cuando ésta no exceda de 5 días hábiles a partir de que presentes tu solicitud. </p>

<sub><i>  En los servicios de telecomunicaciones se encuentran incluidos: telefonía, datos, banda ancha, Internet, radiocomunicación especializada de flotillas (trunking o radio) y televisión de paga. <br/>
  Art. 191, fracción IV de la Ley Federal de Telecomunicaciones y Radiodifusión (en lo sucesivo, LFTyR); 1°, fracción II y 43 de la Ley Federal de Protección al Consumidor (en lo sucesivo, LFPC) <br/>
  Art. 7 y 32 de la LFPC. <br/>
  Art. 43 de la LFPC. <br/>
  Art. 86 BIS de la LFPC, y numeral 5.2.17.1 de la Norma Oficial Mexicana NOM-184-SCFI-2012, Prácticas comerciales-Elementos normativos para la comercialización y/o prestación de los servicios de telecomunicaciones cuando utilicen una red pública de telecomunicaciones. (en lo sucesivo, NOM 184.)<br/>
  Art. 191, fracción III de la LFTyR; Regla 13, fracción IV de las Reglas de Portabilidad Numérica.
</i></sub>

<p>Fobos hace de su conocimiento que los Datos Personales proporcionados voluntariamente por usted, son recabados con 2 finalidades:</p>

<p>  TÚ TIENES DERECHO a partir de la fecha en que se ejecute la portabilidad y sin la exigencia de requisitos adicionales, a finiquitar de manera automática la relación contractual con el proveedor que antes te prestaba el servicio, únicamente de aquellos servicios cuya prestación requiera de los números telefónicos a ser portados, por lo que los servicios que no requieran de los números telefónicos portados podrán continuar activos en los términos establecidos en los contratos. </p>

<p> TÚ TIENES DERECHO a que el proveedor con el que decidas cambiarte, te proporcione la información necesaria para acceder al Sistema de Información en internet que te permita saber el estado que guarda tu solicitud de portabilidad.  </p>

<p>Una vez que has iniciado el proceso de portabilidad, si así lo decides, tienes derecho a cancelarlo de manera gratuita. </p>

<p>En caso de no manifestar su negativa al tratamiento de Datos Personales con las finalidades secundarias anteriormente descritas en el plazo de 5 (cinco) días hábiles, se le tendrá por consentido y aceptado el uso y tratamiento de sus Datos Personales para dichas finalidades; sin embargo, esto no será impedimento para que usted en todo momento pueda ejercitar sus Derechos ARCO que se describen en el numeral 6 del presente aviso de privacidad.</p>

<p>TÚ TIENES DERECHO a recibir el pago de las penas convencionales previstas en el contrato, en caso de que no se cumplan los plazos de portabilidad correspondientes. </p>

<b><p>4.	Desbloqueo de equipo o dispositivo. </p></b>

<p>TÚ TIENES DERECHO, cuando adquieras un equipo, a que el proveedor te informe por escrito si ese equipo se encuentra bloqueado para que sólo pueda ser utilizado en su red y en tal caso, debe informarte cómo podrá ser desbloqueado. </p>

  <p>TÚ TIENES DERECHO al desbloqueo del equipo móvil cuando concluya el plazo del plan contratado, o bien, cuando hayas liquidado el precio total del equipo. Cuando lo hayas pagado de contado en un esquema de pre-pago, el proveedor o su distribuidor te deberán proporcionar la clave de desbloqueo de tu equipo al momento de entregártelo.  </p>
 

<b>III. DERECHO A CONDICIONES DE CONTRATACIÓN CLARAS, JUSTAS Y EQUITATIVAS.</b> 
<b>5.	Información sobre términos y condiciones de contratación</b>

<p>TÚ TIENES DERECHO a conocer, antes de contratar, todos los términos y condiciones establecidos en el contrato de adhesión, de manera clara, suficiente, veraz y precisa. Dicho contrato deberá estar disponible en la página de Internet del proveedor.  </p>


<p>Al momento de contratar, el proveedor deberá entregarte por escrito el contrato de adhesión, el cual debe estar escrito en idioma español y con caracteres legibles a simple vista. </p>


<p>El contrato de adhesión deberá contener, cuando menos, la descripción del servicio que contratarás, las obligaciones de las partes, los términos y condiciones bajo los cuales se prestarán los servicios, las penas convencionales y los supuestos de terminación y modificación. Asimismo, deberá contener las especificaciones técnicas y comerciales, incluyendo la calidad y, en su caso, velocidad del servicio de telecomunicaciones a contratar, entre otras. </p>


<sub><i>
Regla 13, fracción VI de las Reglas de Portabilidad Numérica. <br/>
  Reglas 13, fracción IX, 34, 38 y 39 de las Reglas de Portabilidad Numérica.<br/>
  Regla 13, fracción XI de las Reglas de Portabilidad Numérica<br/>
  Regla 13, fracción XII de las Reglas de Portabilidad Numérica.<br/>
  Reglas 13 fracción VI, 14 y 16 de las Reglas de Portabilidad Numérica.<br/>
  Art. 191, fracción XII de la LFTyR; numeral 4.2.2. de la NOM 184.<br/>
  Art. 191, fracción V de la LFTyR.<br/>
  Art. 85 de la LFPC<br/>
  Art. 191, fracción VII de la LFTyR; numerales 4.6 y 5 de la NOM 184.<br/>
  </i>
</sub>

          </div>
    </div>
           

                </Container>
                
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Carta);
import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { withStyles } from '@material-ui/core/styles';

import { UserContext } from '../contexts/UserContext';
import AppLayout from '../components/AppLayout';
import { Grid } from "@material-ui/core";
import Telinfo from '../components/Telinfo';
import Facturacion from '../components/Facturacion';
import Servicios from '../components/Servicios';
import Cuenta from '../components/Cuenta';
import Tienda from '../components/Tienda';
import Carrito from '../components/Carrito';
import Recargas from '../components/Recargas';
import Consumos from '../components/Consumos';
import { main_subsections } from '../config.js';

const styles = theme => ({
    // Add your style definitions here if necessary
});

const Dashboard = ({ classes }) => {
    const { user } = useContext(UserContext);
    const location = useLocation(); // useLocation hook to get location data
    const hash = location.hash || '#telinfo'; // Default to #telinfo if no hash is present

    return (
        <AppLayout logout={true} title="Dashboard" tabs={main_subsections} tab={hash}>
            {hash === '#telinfo' || hash === '' ? (
                <div id="#telinfo">
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Telinfo />
                        </Grid>
                    </Grid>
                </div>
            ) : null}

            {hash === '#facturacion' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Facturacion />
                        </Grid>
                    </Grid>
                </div>
            )}

            {hash === '#servicios' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Servicios />
                        </Grid>
                    </Grid>
                </div>
            )}

            {hash === '#cuenta' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Cuenta />
                        </Grid>
                    </Grid>
                </div>
            )}

            {hash === '#consumos' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Consumos />
                        </Grid>
                    </Grid>
                </div>
            )}

            {hash === '#tienda2' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Tienda />
                        </Grid>
                    </Grid>
                </div>
            )}

            {hash === '#carrito2' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Carrito />
                        </Grid>
                    </Grid>
                </div>
            )}

            {hash === '#recargas' && (
                <div>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Recargas />
                        </Grid>
                    </Grid>
                </div>
            )}
        </AppLayout>
    );
};

export default withStyles(styles)(Dashboard);

import React, { Component } from "react";

//Material core
import { withStyles } from '@material-ui/core/styles';

import {
    TextField,
    Snackbar,
    IconButton,
} from '@material-ui/core/';

import CloseIcon from "@material-ui/icons/Close";

import Alert from '@material-ui/lab/Alert';

//import Layout from '../components/Layout';
//import { getToken, setToken } from '../functions.js';

const styles = theme => ({
    toolbar : {
        backgroundColor : theme.palette.primary.main
    }
})

class AlertMassage extends Component {

    componentDidMount() {

    }

    render(){
        const { classes, message, open, onClose, severity  } = this.props;


        return (
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center"
                }}
                open={open}
                autoHideDuration={5000}
                onClose={onClose}
                variant="error"
                ContentProps={{
                    "aria-describedby": "message-id"
                }}
                //message={message}
                // action={[
                //     <IconButton color="inherit" key="close" onClick={onClose}>
                //         <CloseIcon />
                //     </IconButton>
                // ]}
            >
                <Alert variant="filled" severity={severity} onClose={onClose} >
                    {message}
                </Alert>
            </Snackbar>
        )
    }
}

export default withStyles(styles)(AlertMassage);

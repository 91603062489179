import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";


import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    
    title:{
        color : '#eb6324',
        fontSize : 24,
        marginTop : 40
    }

})
var user = null;

class Colaboracion extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            

            <Grid container alignItems="center" justify="center" spacing={0}>
              

                <Container maxWidth='md'>
                <Grid  item>
                <img onClick={()=>{window.location="/"}} src="logofte.svg" style={{height:44, marginTop:30,cursor:'pointer'}}/>
                <div className={classes.title}>Colaboración con la justicia</div>
                </Grid>

                <div >
    
          <div >

<p>        
En cumplimiento a lo establecido en los artículos 189 (ciento ochenta y nueve) y 190 (ciento noventa) de la Ley Federal de Telecomunicaciones y Radiodifusión, así como a los Lineamientos de colaboración en materia de Seguridad y Justicia, Fobos Telecomunicaciones S.A. de C.V. pone a disposición del público en general los datos del Área responsable para la atención de requerimientos de información en materia de seguridad y justicia, única y exclusivamente provenientes de Autoridades Facultadas y Designadas, sobre datos conservados, geolocalización en tiempo real de dispositivos o equipos terminales móviles, y órdenes para la intervención de comunicaciones.</p>

<b>Legal</b>
<p>Área responsable </p> 

<b>Domicilio</b>
<p>Domicilio Circuito Paseo Tuna no. 18 local 217 int. C., Fraccionamiento Zibatá, CP. 76269, El Marqués, Querétaro.</p>

<b>Correo electrónico</b>

<p>justicia@fte.net.mx</p>

          </div>
    </div>
           

                </Container>
                
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(Colaboracion);
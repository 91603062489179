import { url_server } from '../config.js'

import { setToken, delToken } from '../functions.js'

import React, { Component } from "react";
import {
    Container,
    FormControlLabel,
    Checkbox,
    Link,
    Grid,
    Box,
    Typography,
    Card,
    CardContent,
    CircularProgress,
} from '@material-ui/core/';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';

import { makeStyles, withStyles } from '@material-ui/core/styles';

import AppLayout from '../components/AppLayout';
//import TextField from '../components/fields/TextField';
import TextField from '@material-ui/core/TextField';
import CheckBoxField from '../components/fields/CheckBoxField';
import ButtonField from '../components/fields/ButtonField';
import AlertMessage from '../components/fields/AlertMessage';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import MaskedInput from 'react-text-mask';
import emailMask from 'text-mask-addons/dist/emailMask'


const styles = theme => ({
    paper: {
        //marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
        marginNormal : {
            marginTop: 0,
            marginBottom: 0
        }
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    textInput : {
        width : '100%',
        marginTop: 5,
        marginBottom: 5,
        border : '0px solid',
        borderBottom : '1px solid #000',
        padding: 10
    }
})

class AfiliaForm extends Component {

    state = {
        error : true,
        message : "Mensaje",
        waiting : false,
        estado : "Querétaro",
        alreadySend : false,
        nombre : "",
        email : "",
        telefono : "",
        estado : "",
        severity : "error", open: false
    }

    closeError = (event) => {
        this.setState({error : false})
    }
    

    closeForm = () =>{
        this.props.closeAfilia()
    }

    sendForm = async (e) =>  {

        e.preventDefault();

        var validators = {
            strlen: { pattern: '^(.){2,80}$', desc:"Requerido de 2 a 80 carácteres." },
            email: { pattern: '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$', desc:"Correo electrónico válido requerido." },
            number_10: { pattern:  '^[0-9]{10,10}$', desc:"Requerido 10 carácteres." },
            decimal: { pattern:  '^[0-9]{1}$', desc:"Requerido un número entre 0 y 9." },
            number: { pattern:  '^[0-9]{0,10}', desc:"Requerido numérico." },
            short_field: { pattern: '^.{1,50}$', desc:"Requerido de 3 a 50 carácteres." },
            imgveh: { pattern: '^.{1,1000}$', desc:"Se requiere una imagen para representar el vehículo, si la imagen no es adecuada favor de contactarnos." },
            year: { pattern: '^.{4,4}$', desc:"Requerido de 4 carácteres ej. 2017 " },
            perc: { pattern: '^.{1,4}$', desc:"Requerido numero entre 1 y 100." },
            long_field: { pattern: '^(.){1,5000}$', desc:"Requerido de 1 a 100000 carácteres." },
            notempty: { pattern: '^.{1,1000}$', desc:"Requerido." },
            anything: { pattern: '^.{0,1000}$', desc:"Requerido." },
            twitter: { pattern: '^.{0,140}$', desc:"Requerido de 2 a 140 carácteres." },
            dashes_only: {pattern:'^[0-9-]*$', desc: "Solo números y guiones"},
            ip_address: {pattern: '^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$',desc:"Ip ip_address"}
    }
        //check errors

        var data = {
            nombre : this.state.nombre,
            email: this.state.email,
            telefono: this.state.telefono,
            estado : this.state.estado
        }

        var errs = []

        //check nombre
        let reg = new RegExp(validators.strlen.pattern,"i");
        if(!reg.exec(data.nombre)){ 
            errs.push("Nombre valido requerido.")
        }

        //check email
         reg = new RegExp(validators.email.pattern,"i");
        if(!reg.exec(data.email)){ 
            errs.push("Email valido requerido.")
        }

        //check telefono
         reg = new RegExp(validators.strlen.pattern,"i");
        if(!reg.exec(data.telefono)){ 
            errs.push("Teléfono 10 dígitos requerido.")
        }

        //check estado
         reg = new RegExp(validators.strlen.pattern,"i");
        if(!reg.exec(data.estado)){ 
            errs.push("Estado requerido.")
        }



        console.log(errs,"errores",this.state.error)        

        
       
        //console.log('DO LOGIN: ' + global.server + ':' + global.port + '/token')
        if(errs.length==0){

            this.setState({waiting:true});
            const request = {
                crossDomain : true,
                credentials: 'include', 
                method  : "POST",
                headers : new Headers({
                    'Content-Type' : 'application/json',
                   // 'Access-Control-Allow-Origin' : '*',
                    
                }),
                body : JSON.stringify(data)
            }
    
            try {
                let url = url_server + '/users/afilia'
        
                const response = await fetch(url, request)
        
                switch(response.status){
                    case 200 : {
                        data = await response.json()
                        this.setState({waiting:false,alreadySend:true})
    
                        break;
                    }
        
                    case 401 : {
                        
                        alert("Error al enviar sus datos")
                        break;
                    }
                }
        
            } catch (err) {
                console.log(err)
            }
        }else{
            console.log("mostrando errores")
            this.setState({waiting:false,open : true,message : errs[0] , severity:"error"})
        }
        
       
    
    }

    render(){
        const { classes } = this.props;

        return (
        
            <div>

            <AlertMessage open={this.state.open} message={this.state.message} onClose={this.closeError} severity={this.state.severity} /> 
        
            {!this.state.alreadySend ? (
                <Grid container spacing={1} direction="row" alignItems="flex-start" justify="center"  >


                <Grid item xs={12}  >
                  
                  <Card className={classes.card}>
                    <CardContent>
                      <h2>
                        Envía tus datos para que nos pongamos en contacto contigo. 
                      </h2>
                      <form className={classes.form} onSubmit={this.login} noValidate>
                        
                        
                        <MaskedInput
                            mask={[/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,/[A-Za-z ]/,]}
                            className={classes.textInput}
                            placeholder="Nombre"
                            guide={false}
                            id="nombre"
                            onBlur={() => {}}
                            onChange={(e) => this .setState({nombre:e.target.value})} 
                        />
                       
                        
                        <MaskedInput
                            mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            className={classes.textInput}
                            placeholder="Teléfono"
                            guide={false}
                            id="tel"
                            onBlur={() => {}}
                            onChange={(e) => this .setState({telefono:e.target.value})} 
                        />

                            <MaskedInput
                            mask={emailMask}
                            className={classes.textInput}
                            placeholder="Email"
                            guide={false}
                            id="email"
                            onBlur={() => {}}
                            onChange={(e) => this .setState({email:e.target.value})} 
                        />

  
                        <Select fullWidth
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={this.state.estado}
                      onChange={(e) => this .setState({estado:e.target.value})}
                      >
                      <MenuItem value={"Aguascalientes"}>Aguascalientes</MenuItem>
                      <MenuItem value={"Baja California Norte"}>Baja California</MenuItem>
                      <MenuItem value={"Baja California Sur"}>Baja California Sur</MenuItem>
                      <MenuItem value={"Campeche"}>Campeche</MenuItem>
                      <MenuItem value={"Chiapas"}>Chiapas</MenuItem>
                      <MenuItem value={"Chihuahua"}>Chihuahua</MenuItem>
                      <MenuItem value={"Coahuila"}>Coahuila</MenuItem>
                      <MenuItem value={"Colima"}>Colima</MenuItem>
                      <MenuItem value={"Ciudad de México"}>Ciudad de México</MenuItem>
                      <MenuItem value={"Durango"}>Durango</MenuItem>
                      <MenuItem value={"Estado de México"}>Estado de México</MenuItem>
                      <MenuItem value={"Guanajuato"}>Guanajuato</MenuItem>
                      <MenuItem value={"Guerrero"}>Guerrero</MenuItem>
                      <MenuItem value={"Hidalgo"}>Hidalgo</MenuItem>
                      <MenuItem value={"Jalisco"}>Jalisco</MenuItem>
                      <MenuItem value={"Michoacán"}>Michoacán</MenuItem>
                      <MenuItem value={"Morelos"}>Morelos</MenuItem>
                      <MenuItem value={"Nayarit"}>Nayarit</MenuItem>
                      <MenuItem value={"Nuevo León"}>Nuevo León</MenuItem>
                      <MenuItem value={"Oaxaca"}>Oaxaca</MenuItem>
                      <MenuItem value={"Puebla"}>Puebla</MenuItem>
                      <MenuItem value={"Querétaro"}>Querétaro</MenuItem>
                      <MenuItem value={"Quintana Rooa"}>Quintana Roo</MenuItem>
                      <MenuItem value={"San Luis Potosí"}>San Luis Potosí</MenuItem>
                      <MenuItem value={"Sinaloa"}>Sinaloa</MenuItem>
                      <MenuItem value={"Sonora"}>Sonora</MenuItem>
                      <MenuItem value={"Tabasco"}>Tabasco</MenuItem>
                      <MenuItem value={"Tamaulipas"}>Tamaulipas</MenuItem>
                      <MenuItem value={"Tlaxcala"}>Tlaxcala</MenuItem>
                      <MenuItem value={"Veracruz"}>Veracruz</MenuItem>
                      <MenuItem value={"Yucatán"}>Yucatán</MenuItem>
                      <MenuItem value={"Zacatecas"}>Zacatecas</MenuItem>
  
  
                      </Select>
  
  
                      
                        
                        {!this.waiting ? (
                            <ButtonField   label='Enviar datos' onClick={(e) => {this.sendForm(e)}} />
                        ): 
                        (
                          <div style={{display: 'flex', justifyContent: 'center'}}>
                              <CircularProgress color="inherit" size={22} />
                          </div>
                        )
                        }
                        
                        
                      </form>
                    </CardContent>
                  </Card>
              </Grid>
              </Grid>
            ): 
            <Card className={classes.card}>
            <CardContent>
                        Sus datos han sido enviados correctamente, brevemente un representante se pondrá en contacto.
                        <ButtonField   label='Aceptar' onClick={this.closeForm} />

                    </CardContent>
                   
                    </Card>
            }
            
              
             
            
              </div>
        );
    }
}

export default withStyles(styles)(AfiliaForm);
import React, {useContext, List, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';
import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo, readOffers, readOfferInfo } from './dataController';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, Tabs, Tab } from "@material-ui/core";
import ProjectCard from "./projectCard";
import AlertMessage from './fields/AlertMessage';


import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../theme.js';


const styles = theme => ({
    root: {
        flexGrow: 1,
      },
})

var user = null;


class Tienda extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,
        dataUser : {} ,
        offers : []    
    } 

    

    static contextType = UserContext;
    
    componentDidMount(){
        this.setState({'waiting':false})
        this.context.loginInfo(()=>{
            let offerid = this.context.user.ALTAN.responseSubscriber.primaryOffering.offeringId;
            this.getOffers(offerid);
        }); 
        
        
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    getOffers(offerid){
        console.log(offerid,"offerid")
        readOffers(offerid).then(data=>{
            this.setState({offers:data})
            console.log(data,"data")
        })
    }

   


    render() {
        const {  history, session } = this.props;
        
        user = this.context.user;
        const data = this.state.data

      

        return (

           

            <div>

                <Backdrop  style={{zIndex:1000}} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 


                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}

                {this.state.waiting ? "": 
                    <div>


                    <h1>Tienda</h1>  

          
                    <Grid container className={styles.root} spacing={2}>
                    <Grid container 
                        direction="row"
                        justify="flex-start"
                        alignItems="flex-start"
                        >
                        
                        {this.state.offers.map( item => (
                        <Grid key={item.NO} item xs={12} md={6} lg={3}  style={{marginBottom:20}} onClick = { () =>{ this.context.cartAdd({'item':1})}}>
                        
                            <ProjectCard 
                               backDOn ={ () => {this.backDOn()}}
                               backDOff ={ () => {this.backDOff()}}
                               
                               key={item.id}
                               item ={item}
                               
                        
                            />
                            </Grid>
                        ))}
                        
                 
                    </Grid>
                    </Grid>

                   

                    </div>
                }     
            </div>
        )
    }

}


export default withStyles(styles)(Tienda);
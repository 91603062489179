import React from "react";
import { compose } from 'recompose'
import { Link, withRouter } from "react-router-dom";
import { makeStyles, withStyles } from '@material-ui/core/styles';
 

// import MUIDataTable from "mui-datatables";
import {
    Fab,
} from '@material-ui/core/';
import {
    Add as AddIcon,
} from '@material-ui/icons/';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';
import { table_options, url_server } from '../config.js';

import AppLayout from '../components/AppLayout';
import {UserContext }  from '../contexts/UserContext';
import {  } from '../components/dataController';

const title = 'Nodos';
const url = 'node';
var user = null;

const styles = theme => ({
    fab : {
        position : 'fixed',
        bottom   : theme.spacing(3),
        right    : theme.spacing(3),
        color    : theme.palette.common.white,
    },
})

class Node extends React.Component {

    state = {
        rootNode : 'MdFuysBgQ' 
    }

    static contextType = UserContext;

    async getRootNode(){

        user = this.context.user;
      /*  readNode(user.rootNode).then(value =>{
            console.log(value)
        });*/
        
        ///this.setState(state => ({types : response}))
    }



    componentDidMount(){
        
        this.context.login(()=>{
            this.getRootNode();
        });        
    }

    createGroup(){

        user = this.context.user;
        
        var obj = {};
				obj.parentNode 	= user.rootNode;  
				obj.label 	   	= "GNODE";
				obj.relType 	= "rel";
				obj.props 		= {}		
				obj.childs =[
				 		{prefix:"b",relationName:'info', label:'INFO',props :{order:'name',status:0}}
                     ]
 
        // createNode(obj).then(val => console.log(val));           
    }



    render(){
        const { classes, history, session } = this.props;
        user = this.context.user;

        return (
            <AppLayout title={title} >
                <div>
                     {user ? <h2>{user.rootNode}</h2> :
                      <h2>Sin ser</h2>} 

            
                      <button onClick={() => {this.createGroup()}}>Crear Grupo</button>


                     
                </div>

            

            

            </AppLayout>
        )
    }
}

export default withStyles(styles)(Node);

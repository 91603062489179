import React, {useContext, List, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { getToken, setToken, getData } from '../functions.js';
import  { UserContext }  from '../contexts/UserContext';
import {  readOffers, readCart } from './dataController';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button } from "@material-ui/core";
import ProjectCard from "./projectCard";
import AlertMessage from './fields/AlertMessage';


import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import TextField from '../components/fields/TextField';
import TextLabel from '../components/fields/TextLabel';
import ButtonField from '../components/fields/ButtonField';
import Section from '../components/Section';

import theme from '../theme.js';


const styles = theme => ({
    root: {
        flexGrow: 1,
      },
})

var user = null;


class Carrito extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,
        dataUser : {} ,
        name : '',
        number : '',
        monthexp : '',
        yearexp : '',
        code : '' 
    } 

    

    static contextType = UserContext;

   

    componentDidMount(){
        this.setState({'waiting':false})
        readCart((res)=>{
            console.log(res)
        })
        // this.getOfferDetail(this.UserContext.OFFERID);
    }

    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    getOfferDetail(offerid){
        console.log(offerid,"offerid")

        readOffers()

        //readOfferInfo('1809964001').then(data=>{
        //    this.setState({offers:data})
         //   console.log(data,"data")
        //})
    }

   


    render() {
        const {  history, session } = this.props;
        
        user = this.context.user;
        const data = this.state.data

      

        return (

            <Section title='Tarjeta de crédito o débito' >
            <Grid container spacing={4}>
                <Grid item md={6} sm={6} xs={12}   >
                    <TextField value={this.state.name} label='Nombre del titular' onChange={event => this.setState({ name: event.target.value })} />
                </Grid>
                <Grid item md={6} sm={6} xs={12}>
                    <TextField value={this.state.number} label='Número de tarjeta' onChange={event => this.setState({ number: event.target.value })} />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField value={this.state.monthexp} label='Mes expiración' onChange={event => this.setState({ monthexp: event.target.value })} />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField value={this.state.yearexp} label='Año expiración' onChange={event => this.setState({ yearexp: event.target.value })} />
                </Grid>
                <Grid item md={3} sm={6} xs={12}>
                    <TextField value={this.state.yearexp} label='CCV' onChange={event => this.setState({ yearexp: event.target.value })} />
                </Grid>
            </Grid>  
        </Section>
         
        )
    }

}


export default withStyles(styles)(Carrito);
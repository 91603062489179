import React, {useContext, List, useState} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { numberWithCommas } from '../../functions';
import  { UserContext }  from '../../contexts/UserContext';
import {  recuperacion } from '../../components/dataController';
//import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress,   Card, CardContent} from "@material-ui/core";
//import RecargaItem from "./RecargaItem";
import AlertMessage from '../../components/fields/AlertMessage';
import ButtonField from '../../components/fields/ButtonField';

import Fade from '@material-ui/core/Fade';
//import Section from '../components/Section';
//import AddCard from '../components/AddCard'
import Select from 'react-select';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';



import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';


var user = null;


const openId = null;

class Recuperacion extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : false,
        dataUser : {} ,
        openId : null,
        cards : [],
        selCard : null,
        cardWaiting : false,
        numbers : [],
        selectedNum : null,
        nombre : '',
        apellidos : '',
        checkout : false,
        subscriptionMode : true
    } 

    

    static contextType = UserContext;
    
    componentDidMount(){

    }

  

      recuperar = () =>{
         this.setState({waiting:true})
        let recQ  = recuperacion(this.state.username).then(res=>{
            this.setState({waiting:false})
            if(res.status=='ok'){
                alert('Se envio exitosamente el correo con su contraseña. por favor reviselo e intente nuevamente ingresar al portal ')
                this.setState({username:''})
                this.props.closeHandle();
            }else{
                alert('No se encontro el usuario en la base de datos. ')
            }
            
        });
        
    }

   
 
   
    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    render() {
        const { classes, history, session } = this.props;   
        user = this.context.user;
        const data = this.state.data

        return (
            <div>
                <Backdrop  style={{zIndex:1000}} open={this.state.waiting} onClick={() =>{this.backDOff()}}>
                    <CircularProgress color="inherit" />
                </Backdrop> 

                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}

                {this.state.waiting ? (
                        <div style={{margin:'20px',marginLeft:'40px'}}>
                        <CircularProgress color="inherit" size={12} style={{float:'left', margin:3}} />
                        <div style={{float:'left'}}>Buscando usuario...</div> 
                        </div>
                    ):null}   

                {this.state.waiting ? "": (
                  
                    <div >
                     <Card className={classes.card}>
                     <CardContent>
                    
                    <h1>Recuperacion de contraseña</h1>  

                    <h2>Ingrese el correo electrónico con el que se registro al portal FTE.</h2>
                    <div style={{float:'left'}}>
                    <input style={{fontSize:'18px',fontWeight:'bold',width:'350px',padding:'5px',marginRigth:'10px'}} value={this.state.username} onChange={(e)=>{ this.setState({username:e.target.value})}}  type='text'  />
                    </div>
                    <div style={{marginLeft:'10px',float:'left','marginTop': '-9px'}}>
                    <ButtonField style={{marginTop:'0px !important'}} onClick={()=>{this.recuperar()}}  label='enviar correo de recuperación' />
                    </div>
                    </CardContent>
                    </Card>
                    
                    </div>
                    )} 
                   
            </div>  
        )
    }

}



const styles = theme => ({
    root: {
        flexGrow: 1,
        padddinTop : 30
      },
      cardT :{
        width:'100%'
      },
      cuadroC :{
        marginTop : 5,
        marginBottom : 5,
        marginLeft: 0,
        marginRight : 0,
        width : '100%',
        float : 'left'
        
    },
    cuadroTarjeta :{
        height : 'auto',
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 10,
        //minHeight:120,
        width : '100%',
        float : 'left',
        alignItems : 'center',
        alignSelf : 'center',
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
        
    },
    modal:{
        "&:focus":{
        outline: 'none'
       }
     },
    cuadroT : {
        backgroundColor : "#fff",
        borderRadius : 6,
        boxShadow: "3px 9px 14px #0000001f",
        border: '1px solid #e4e4e4',
        padding : 20,
        marginBottom : 30,
        "&:hover": {
            backgroundColor: '#323b5c',
            color : "#fff !important"
          }
    },
  
    cuadroSel : {
        backgroundColor: '#333',
        color : "#fff !important"

    },
   
}) 
export default withStyles(styles)(Recuperacion);
import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

//import Layout from '../components/Layout';
import { numberWithCommas } from '../functions.js';

import  { UserContext }  from '../contexts/UserContext';
import { readUserInfo, readOfferInfo, readUserView, readUserANY} from './dataController';
import Section from '../components/Section';
import AppLayout from './AppLayout';
import { Grid, Backdrop, CircularProgress, Button, LinearProgress } from "@material-ui/core";
import ProjectCard from "./projectCard";
import AlertMessage from './fields/AlertMessage';

import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import ProgressProvider from "../ProgressProvider";
import theme from '../theme.js';
import { mergeClasses } from "@material-ui/styles";



const styles = theme => ({
    bar: {
        borderRadius: 8,
        height: '16px',
        marginRight : 20,
        //backgroundColor: '#1a3a63',
      },
      colorPrimary: {
        backgroundColor: '#1a3a63',
      },
      barColorPrimary: {
        backgroundColor: '#eb6324',
      }
})
var user = null;


class Telinfo extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,
        dataUser : {},
        freeUnits : [],
        resumeUnits : [],
        selMSISDN : 0,
        numbers : [],
        actualN : [],
        actualInt : 0,
        numbersRes : [],
        numberOn : "",
        passOn : true,
        title : "" 
    } 


    static contextType = UserContext;

    async componentDidMount(){
        this.mounted = true;
        this.setState({'waiting':true})

        this.context.login( (res) => {
            console.log(res,"login response",this.context.user)
            var filterN = [];

            res.numbers.map(item =>{
                if(item.status==0 || item.status==undefined ){
                    filterN.push(item)
                }
            })

            console.log(filterN,"filterN")

            this.setState({ numbers: filterN }, () => {
                this.initDown();
            });
        });      
    }

    componentWillUnmount() {
       
    }

    async initDown(){

        let numberInfo = this.state.numbers[this.state.actualInt]

        console.log(this.state.numbers,this.state.actualInt)

        //if(numberInfo.status==1 && (this.state.actualInt<this.state.numbers.length)){
        //    let numberInfo = this.state.numbers[this.state.actualInt++]
        //}
        
        console.log(numberInfo,"numberInfo", this.state.actualInt,this.state.numbers)

        if(this.state.passOn){
            this.setState({passOn:false})

            console.log(numberInfo,"number Info")

            await readUserANY(numberInfo.msisdn,numberInfo.icc).then(dataAltan =>{

                if(!dataAltan.errorCode){
                    dataAltan.info =  numberInfo;
                
                    let procedData = ""
                    let resumeData = ""


                    // Reorder 

                    

                    if(!dataAltan.errorCode){
                        procedData = this.proc(dataAltan);
                        resumeData = this.procResume(dataAltan)
                        procedData.resume = resumeData;
                        
                    }else{
                        //console.log('regreso proc2')
                        procedData = this.proc({data:{msisdnStatus:'Erroneo'}});
                        resumeData = this.procResume({data:{msisdnStatus:'Erroneo'}});
                        procedData.resume = resumeData;
                    }
        
                    let numberOn = ""
                    if(this.state.numbers[this.state.actualInt+1]){
                        numberOn = this.state.numbers[this.state.actualInt+1].msisdn
                    }else{
                        
                    }

                    this.setState({actualInt:this.state.actualInt+1,waiting:true,numberOn:numberOn,passOn:true})
                
                if(!dataAltan.errorCode){
                    console.log('setting datas ', procedData)
                    this.setState(prevState => ({
                        freeUnits: [...prevState.freeUnits, procedData],
                      }))

                    /*  this.setState(prevState => ({
                        resumeUnits: [...prevState.resumeUnits, resumeData],
                      }))*/
                }
                
                
                
                  
                this.setState({passOn:true},function(){
                    if((this.state.actualInt<this.state.numbers.length)  ){
                        //console.log('procesando...', this.state.actualInt)
                         this.initDown()
                     }else{
     
                        // console.log("termino numeros",this.state.numbersRes)
                         //this.setState({backDOn:true})
                         //this.proc()
                         this.setState({waiting : false})
                     }
                })


                }else{
                    console.log(dataAltan.errorCode)
                    this.setState({message : "No se pudo obtener informacion del número " + numberInfo.msisdn,severity:"error",msg:true})

                    if((this.state.actualInt+1<this.state.numbers.length)  ){
                        //console.log('procesando...', this.state.actualInt)
                         this.initDown()
                     }else{
     
                        // console.log("termino numeros",this.state.numbersRes)
                         //this.setState({backDOn:true})
                         //this.proc()
                         this.setState({waiting : false})
                     }
                    //this.initDown()

                }
                
                
                
                

               
                
            });
        }
         
    }

    procResume(dataAltan){

        var data = JSON.parse(JSON.stringify(dataAltan))
        var freeUnits = []

        var bolsasData = ['FU_ThrMBB_Altan-RN_1Mbps_CT',
                         'FU_ThrMBB_Altan-RN_512kbps_CT',
                          'FU_Data_Altan-RN_CT',
                          'FU_Data_Altan-NR-IR_NA_CT',
                          'FU_Data_Altan-NR-IR_NA',
                          'FreeData_Altan-RN',
                          "FU_ThrMBB_Altan-RN_512kbps"];
        var bolsasMIN = ['FU_Min_Altan-NR-IR-LDI_NA','FU_Min_Altan-NR-LDI_NA'];
        var bolsasSMS = ['FU_SMS_Altan-NR-IR-LDI_NA','FU_SMS_Altan-NR-LDI_NA'];
        
    

        var units = data.responseSubscriber.freeUnits
        var status = data.responseSubscriber.status.subStatus


      

        //console.log(units,status,'data')

        if(status=="Active"){
                
            var total_MB = 0;
            var unused_MB = 0;
            let total 
            let used  
            let free 
            let perc  

           
            // FREE MB
               for (let index = 0; index < units.length; index++) {
                console.log('pasando',units[index].name)
                    if(bolsasData.indexOf(units[index].name)!=-1){
                        console.log('pasando',units[index].freeUnit.totalAmt)
                        total_MB += parseInt(units[index].freeUnit.totalAmt);
                        unused_MB += parseInt(units[index].freeUnit.unusedAmt);
                    } 
            }


            total  = parseFloat(total_MB).toFixed(0)
            used   = parseFloat(total_MB-unused_MB).toFixed(0)
            free  = unused_MB.toFixed(0)
             perc   =   ((used / total) ) || 0
           
            var obj = {
                name:"freeUnits",
                desc:"DATOS",
                perc:perc,
                total:total,
                used : used,
                free:free
            }

           // console.log(obj,"MB")
            freeUnits.push(obj)

            // MINUTES

            var total_MIN = 0;
            var unused_MIN = 0;
            for (let index = 0; index < units.length; index++) {
                    if(bolsasMIN.indexOf(units[index].name)!=-1){
                        total_MIN += parseInt(units[index].freeUnit.totalAmt);
                        unused_MIN += parseInt(units[index].freeUnit.unusedAmt);
                    } 
            }

          
            total  = parseFloat(total_MIN|| 0).toFixed(0) 
             used   = parseFloat(total_MIN-unused_MIN || 0).toFixed(0) 
             free = parseFloat(unused_MIN  || 0)
             perc   =   ((used / total) ) || 0 
        
            var obj = {
                name:"minutes",
                desc:"MINUTOS",
                perc:perc,
                total:total,
                used : used,
                free:free
            }
            console.log(obj,"MB")
            freeUnits.push(obj)

            // SMS

            var total_SMS = 0;
            var unused_SMS = 0;
            for (let index = 0; index < units.length; index++) {
                    if(bolsasSMS.indexOf(units[index].name)!=-1){
                        total_SMS += parseInt(units[index].freeUnit.totalAmt);
                        unused_SMS += parseInt(units[index].freeUnit.unusedAmt);
                    } 
            }

             total  = parseFloat(total_SMS || 0).toFixed(0) 
             used   = parseFloat(total_SMS-unused_SMS || 0).toFixed(0) 
             free =  parseFloat(unused_SMS || 0) || 0
             perc   =   ((used / total) ) || 0 
          
            var obj = {
                name:"sms",
                desc:"SMS",
                perc:perc,
                total:total,
                used : used,
                free:free
            }
            console.log(obj,"MB")
            freeUnits.push(obj)


           



           data.calc = freeUnits;
        }else{
           
        }
        
       
        return data;

        //this.procOffers(data.responseSubscriber.freeUnits);
       // this.getOfferInfo(data.responseSubscriber.primaryOffering.offeringId)
       // this.setState({data:data,waiting:false, freeUnits : numbersArr})

 
    
}

    proc(dataAltan){

            var data = JSON.parse(JSON.stringify(dataAltan))
            var freeUnits = []

            var exclude = ['FU_Redirect_Altan-RN', 'FU_Data_Altan-RN_RG18'];  //'FreeData_Altan-RN'
            var units = data.responseSubscriber.freeUnits
            var status = data.responseSubscriber.status.subStatus

            var unitsorder = [
               
                'FU_Data_Altan-RN_CT',
                'FU_Data_Altan-NR-IR_NA_CT',
                'FU_Data_Altan-NR-IR_NA',
                'FU_ThrMBB_Altan-RN_512kbps',
                'FU_ThrMBB_Altan-RN_1Mbps_CT',
                'FU_ThrMBB_Altan-RN_512kbps_CT',
                'FreeData_Altan-RN',
                'FU_Min_Altan-NR-IR-LDI_NA',
                'FU_Min_Altan-NR-LDI_NA',
                'FU_SMS_Altan-NR-IR-LDI_NA',
                'FU_SMS_Altan-NR-LDI_NA',
               
            ] 

            var unitsTitle = [
                '',
                '',
                '',
                '',
                '',
                '',
                '',
                'Detalle minutos',
                '',
                'Detalle SMS',
                '',
            ]

            for (let i = 0; i < units.length; i++) {
                var ind = unitsorder.indexOf(units[i].name);
                units[i].index = ind
                units[i].title = unitsTitle[ind]
            }

            units.sort(function (a, b) {
                //console.log(a.name,b.name,"sorting")
                return a.index - b.index;
              })


             // console.log(units,'units after')

            if(status=="Active"){

                for (let index = 0; index < units.length; index++) {

                    var total = 0;
                var unused = 0;

                            //console.log(units[index],'units')
                            if( exclude.indexOf(units[index].name)==-1){
                            total += parseInt(units[index].freeUnit.totalAmt);
                            unused += parseInt(units[index].freeUnit.unusedAmt);
                            let totals  = parseFloat(total).toFixed(0)
                            let used   = parseFloat(total-unused).toFixed(0)
                            let free  = unused.toFixed(0)
                            let perc   =   ((used / total) * 100 ) || 0
                           

                            var name = this.searchName(units[index].name)

                                console.log(units[index].name,"RTY")

                            var obj = {
                                name:units[index].name,
                                desc:name,
                                perc:perc,
                                total:totals,
                                used : used,
                                free:free,
                                titled: units[index].title
                            }

                        let fechaFormat = units[index].detailOfferings[0].expireDate;
                        let year = fechaFormat.substring(0,4);
                        let month = fechaFormat.substring(4,6);
                        let day = fechaFormat.substring(6,8)
                        let fechaF = day + "/" + month + "/" + year 
                        obj.expireDate = fechaF

                         //   console.log(obj,"obj")
                        
                                freeUnits.push(obj)
                        }
                            
                }

                

               
               data.calc = freeUnits;
         
            }
            return data;

            //this.procOffers(data.responseSubscriber.freeUnits);
           // this.getOfferInfo(data.responseSubscriber.primaryOffering.offeringId)
           // this.setState({data:data,waiting:false, freeUnits : numbersArr})

     
        
    }

    searchName(name){

        var traducciones = [
            {offer: 'FU_ThrMBB_Altan-RN_1Mbps_CT' , desc: 'Datos para compartir en México con velocidad controlada (1 mbps)'},
            {offer: 'FU_ThrMBB_Altan-RN_512kbps_CT' , desc: 'Datos para compartir en México con velocidad controlada (512 kbps)'},
            {offer: 'FU_Data_Altan-RN_CT' , desc: 'Datos para compartir en México '},
            {offer: 'FU_Data_Altan-NR-IR_NA_CT' , desc: 'Datos para compartir en México, EU y Canadá'},
            {offer: 'FU_Data_Altan-NR-IR_NA' , desc: 'Datos México, EU y Canadá'},
            {offer: 'FU_ThrMBB_Altan-RN_512kbps' , desc: 'Datos México con velocidad controlada (512 kbps)'},
            {offer: 'FreeData_Altan-RN' , desc: 'Datos México'},
            {offer: 'FreeMinutes_Altan-RN' , desc: 'Minutos con origen en México EU ó Canadá y destino a México, EU y Canadá'},
            {offer: 'FU_Min_Altan-NR-IR-LDI_NA' , desc: 'Minutos con origen en México EU ó Canadá y destino a México, EU y Canadá'},
            {offer: 'FU_Min_Altan-NR-LDI_NA' , desc: 'Minutos con origen en México y destino México, EU y Canadá'},
            {offer: 'FU_SMS_Altan-NR-IR-LDI_NA' , desc: 'SMS con origen en México EU ó Canadá y destino a México, EU y Canadá'},
            {offer: 'FU_SMS_Altan-NR-LDI_NA' , desc: 'SMS con origen en México y destino México, EU y Canadá'},
            
        ] 


        for (let i = 0; i < traducciones.length; i++) {
            if(traducciones[i].offer==name){
                return  traducciones[i].desc
            }
            
        }

        return name;
    }

    procOffers(offers){
        var offArr = [];
        for (var i=0; i<offers.length;i++){
            offArr.push({offerid:offers[i].detailOfferings[0].offeringId, name :offers[i].name })
        }
        //console.log(offArr)
    }


    backDOff(){
        this.setState({'waiting':false})
    };

    backDOn(){
        this.setState({'waiting':true})
    };
    

    


    setMsg(message){
        this.setState({message : message,severity:"success",msg:true})
    }

    closeMsg = (event) => {
        this.setState({msg : false})
    }

    comprarSaldo = () =>{
        console.log("comprar saldo")
        window.location = "/dash#tienda"
    }


    filterTipo(item,all){

        var status = all.responseSubscriber.status.subStatus
        if(status=='Active'){
            let exclude = "FU_Data_Altan-RN_RG18";
            var dated = 0
            for (let index = 0; index < item.length; index++){
                var datedon = item[index].detailOfferings[0].expireDate;

                if(datedon>dated && item[index].name!=exclude){
                dated = datedon; 
                }
                
            }

            let empty;
      //  console.log(all,"all")
       //console.log(all.calc[0].total, all.calc[1].total,all.calc[2].total)
        if(all.calc[0].total==0 && all.calc[1].total==0 && all.calc[0].total==0){
            empty = true;
        }

        var filter = [];
        if(item){
            for (let index = 0; index < item.length; index++) {
                if(item[index].offerInfo && item[index].offerInfo.ACCION=="ACTIVACION"){
    
                    //console.log(item[index],"item on")    
                    let fechaFormat = dated || "" //item[index].detailOfferings[0].expireDate || "";
                    let year = fechaFormat.substring(0,4);
                    let month = fechaFormat.substring(4,6);
                    let day = fechaFormat.substring(6,8)
                    let fechaF = day + "/" + month + "/" + year 
                    item[index].expireDate = fechaF
    
                    filter.push(item[index])
                    break;
                }
                
            }  

            
        }
        

       
    
            return (filter.map(inter =>(
                <div key={inter.msisdn}>
              {/*  <div style={{float:'left'}} >  {inter.offerInfo.NOMBRECOMERCIAL} 
                {!empty ? <div><b>Expira</b> {inter.expireDate}</div> : null} 
                {empty ? <div><h3 style={{color:'red'}}>El número no cuenta con bolsas disponibles, haga una recarga.</h3></div> : null}
                 </div>

            */}
                
               
                 </div>
            ))
    
            )
        } else{
            return [];
        }
        
        
    }

    render() {
        const {  history, session, classes } = this.props;

        const user = this.context.user;

        const buildStylesV = buildStyles({
            rotation: .75,
            strokeWidth: 2,
            textSize: '10px',
            pathTransitionDuration: 1,
            pathColor: '#ec6223',
            textColor: '#000',
            trailColor: '#1a3a63',
            backgroundColor : '#fff',
            backgroundColor: '#fff',
            transition: 'stroke-dashoffset 2s ease 0s',
            transform: 'rotate(0.25turn)',
            transformOrigin: 'center center',
            strokeLinecap: "butt"
          })
        const data = this.state.data
       
       
 
        return (
            <div>

                {this.state.msg ? <AlertMessage open={this.state.msg} message={this.state.message} severity={this.state.severity} onClose={this.closeMsg} /> : null}

               
                     <div>

                     {this.state.freeUnits.length==0 && !this.state.waiting ? (
                         <h2>No se encontró información del número</h2>
                     ): null}    
                        
                

                        {this.state.freeUnits && this.state.freeUnits.map((numb) => (
                           <Section key={numb.info.msisdn}>
                             
                            <div style={{width:'100%',textAlign:'left',float:'left',fontSize:'28px',fontWeight:'bold', marginBottom:'20px', padding :'10px'}}>
                                {numb.info.msisdn} &nbsp;
                                 <div style={{fontSize:'28px',float:'right',marginLeft:"20px",marginRight:"10px"}}>{numb.info.alias}</div>
                               
                            </div>
                         
                         {/* {this.filterTipo(numb.responseSubscriber.freeUnits,numb)}     */}
                            
                            {numb.responseSubscriber.status.subStatus!="Active" ? (
                                <h2>{numb.descriptionStatus}</h2>  
                            ): (
                            
                    <div>

                    {numb.resume.calc ? (
                             <Grid
                             container
                             direction="row"
                             justify="left"
                             alignItems="left"
                             style={{paddingTop :'20px',marginBottom:0,backgroundColor: '#fff', padding: '20px', borderRadius: '10px', marginTop: '10px', marginBottom: '20px'}}
                             >
                             
                    {numb.resume.calc.map( itemdr => (
                
                <Grid key={itemdr.id} item xs={12} sm={4} style={{justify:'center',alignSelf:'center'}}>
                <div key={itemdr.name} style={{textAlign:'center',fontSize:'12px',justify:'center', width:140, margin:'20px auto'}} >

                <ProgressProvider valueStart={100} valueEnd={itemdr.perc}>
                {(value) => <CircularProgressbar circleRatio={0.5} value={itemdr.perc} maxValue={1}  strokeWidth={25}  styles={buildStylesV} />}
                </ProgressProvider>

                
                <div  style={{marginTop:-60}}>
                <div style={{marginTop:15, fontSize:22, fontWeight:'bold'}}>{itemdr.desc}</div>
                <div style={{justify:'center', marginBottom:40,marginTop:-16}}>
                    <div style={{marginTop:20}}>Usados: <b>{numberWithCommas(itemdr.used)}</b></div>
                    <div style={{marginTop:0}}>Libres: <b>{numberWithCommas(itemdr.free)}</b> </div>
                    <div style={{marginTop:0}}>Totales: <b>{numberWithCommas(itemdr.total)}</b> </div>
                    {/* <p style={{marginTop:0}}>Totales: {item.total}</p> {itemd.used + " / " + itemd.total */}
                    
                </div>
                </div> 
                </div>
                </Grid>
                               
                                 
                ))}
                             
                </Grid>  
                 ) : null}

                {/*  FECHA EXPIRACION Y PLAN  */}

                               <Grid
                            container
                            direction="row"
                            justify="left"
                            alignItems="left"
                            style={{paddingTop : 0,marginBottom:0}}
                            >
 
                            {numb.calc.length==0 ? ( <h3 style={{color:'#eb6324'}}>El numero no cuenta con bolsas de datos activas.</h3>) : null}
                            
                            <div style={{width:'100%'}}>
                            <div style={{width:'100%', fontSize:'24px',fontWeight:'bold', paddingLeft:'10px'}}>Detalle datos</div>  
                            </div>




                            {numb.calc.map( itemd => (
                                 <React.Fragment>
                               
                               {(itemd.titled!='') ? (
                                      <Grid item xs={12}>
                                        <div style={{width:'100%', fontSize:'24px',fontWeight:'bold', paddingLeft:'10px', marginTop:'20px'}}>{itemd.titled}</div>
                                     </Grid>
                                     ): null}
                      
                                <Grid item key={itemd.id} item  xs={12} sm={6} style={{justify:'center',alignSelf:'center', paddingLeft:'10px', paddingRight:'10px'}}>

                               
                            
                                <div key={itemd.name}  >

                           
                                <div style={{marginTop:20, fontSize:16}}>{itemd.desc}</div>
                                <div style={{float:'left', fontSize:12, width:'100%', marginBottom:'10px'}}>Expira {itemd.expireDate}</div>
                                
                                <div style={{float:'left', width:'100%'}}>
                                <LinearProgress classes={{colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary, bar: classes.bar, root: classes.bar}} variant="determinate"  value={itemd.perc} />
                                </div>


                                <div style={{justify:'left',marginTop:0}}>
                                    <div style={{marginTop:5,float:'left',marginRight:'15px',marginBottom:10}}>Usados: <b>{numberWithCommas(itemd.used)}</b></div>
                                    <div style={{marginTop:5,float:'left',marginRight:'15px',marginBottom:10}}>Libres: <b>{numberWithCommas(itemd.free)}</b> </div>
                                    <div style={{marginTop:5,float:'left',marginRight:'15px',marginBottom:10}}>Totales: <b>{numberWithCommas(itemd.total)}</b> </div>
                                </div>
                                
                                </div>

                                </Grid>
                                </React.Fragment>
                            ))}
                            
                            </Grid>  
                            </div>
                            )}
                            
                          
                           
                           
                            
                           
                            </Section>
                        ))}

                 
                     </div>
            
                {this.state.waiting ? (
                    <div style={{textAlign:'center',justify:'center',alignSelf:'center'}}>
                       <h1>Conectando con la red FTE... {this.state.numberOn}</h1>
                       <CircularProgress color="inherit"  />
                     </div>
                ) : null}
                
                  

               
                
            </div>
        )
    }

}


export default withStyles(styles)(Telinfo);



  /*
   {
            "icc" : "8952140061756034895", 
            "msisdn" : "4613471005", 
            "type" : "main",
            "alias" : "Programación Rodrigo"
        },
        {
            "icc" : "8952140061756035330", 
            "msisdn" : "5510021292", 
            "type" : "main",
            "alias" : "Arturo principal"
        },
        {
            "icc" : "8952140061756026008", 
            "msisdn" : "5628282323", 
            "type" : "sec",
            "alias" : "Oficina"
        },
        {
            "icc" : "8952140061756029358", 
            "msisdn" : "4428906005", 
            "type" : "main",
            "alias" : "Leonel"
        },
        

        //'4428950891' //'5628282323' //
        /* 
        Patrón 5628282323  8952140061756026008
        Hector 5628230800
        Leonel 4428906005 8952140061756029358
        Mio 4613473137  
        */

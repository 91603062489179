/*--------------------------------------------------
*	PH = Placehorder
	BT = Button
	TX = Text
	IE = Input Error
	NR = Net response

--------------------------------------------------*/


export function lang(lang){
    
    if(lang=="en"){
        return english
    }
    if(lang=="es"){
        return spanish
    }
}


const english = {

	BT_signup   		: "Sign up for closed Beta",
	TX_signup 			: "Sign up with your e-mail",

	BT_login 			: "Log in",
	TX_login			: "Log in with your e-mail",

	BT_cancel 			: "Cancel",
	BT_delete			: "Delete",
	BT_config			: "Configuration",
	BT_new				: "New",

	BT_saveTemplate     : 'Save Template',

	BT_share 			: "Share",
	BT_rename			: "Rename",

	TX_email 			: "E-mail",
	PH_email 			: "youremail@domain.com",
	TX_password 		: "Password",
	TX_confirm  		: "Confirm",
	TX_creating 		: "Creating account...",
	PH_password 		: "(6-20 Characters)",
	PH_passwordL 		: "password",
	PH_passwordConfirm 	: "Confirm password",
	PH_newProject 		: "Project Name",
	PH_newGroup 		: "Group Name",
	PH_name 			: "name",
	PH_value 			: "value",

		// HEADER
	BT_logout			: "Logout", 	
	BT_templates		: "Templates", 	
	BT_profile			: "Profile", 	
	BT_groups			: "Groups", 	
	BT_views			: "Views", 	
	BT_controlPanel		: "Control Panel", 	
	
	
	
	BT_addProject 		: "Add Project",
	BT_addGroup 		: "Add Group",
	BT_addNode  		: "Add Node",
	BT_addField  		: "Add Field",

	TX_titDetail		: "Node Detail",
	TX_fieldType		: "Field Type",
	TX_mask				: "Mask",
	BT_saveConfig 		: "Save Config",

	BT_FAQ				: "F.A.Q.",

	IE_email			: "Vaild e-mail is required",
	IE_password			: "Password is required (6-20 Characters)",
	IE_passwordLen		: "Password length must be between 6-20 Characters",
	IE_confirmp			: "Passwords don´t match",

	IE_empty			: "Project Name must be at least 3 Chars",
	IE_taken			: "You already have a project with that name",
	IE_createdProject	: "Project was succesfully created.",
	IE_createdProjectErr: "Error creando el proyecto.",
	IE_delProject 		: "Project succesfully deleted.",
	IE_createdGroup 	: "Group was succesfully created.",
	IE_createdGroupErr 	: "Error creating group node, database not available.",
	IE_deleteGroup	 	: "Group Node was succesfully deleted.",

	TX_about			: "About teranodo",
	TX_privacy			: "Privacy",
	TX_terms			: "Terms of use",
	TX_updating			: "updating",

	BT_createAccount	: "Sign Up",

	TX_caps				: "Caps lock is on",
	TX_existant			: "Email is already registered.",
	TX_accept   		: 'By clicking "Sing Up" you are agreeing to the ',
	TX_privacy			: "Privacy policy",
	TX_terms			: "Terms of service",
	TX_and 				: "and ",
	TX_show				: "show",
	TX_hide				: "hide",

	NR_wrongPass  		: "Login Error, Check Username and Password",

	TX_deleteProject 	: "Delete Project?. All nodes and relations will be deleted permanently. Please write project name to delete (case sensitive)."

}

 const spanish = {

	BT_signup   		: "Regístrate ",
	TX_signup 			: "Regístrate con tu correo electrónico",

	BT_login 			: "Ingresar",
	TX_login			: "Ingresa con tu correo electrónico",

	BT_cancel 			: "Cancelar",
	BT_delete			: "Borrar",
	BT_config			: "Configurar",
	BT_new				: "Nuevo",

	BT_saveTemplate     : 'Guardar Plantilla',

	BT_share 			: "Compartir",
	BT_rename			: "Renombrar",

	TX_email 			: "E-mail",
	PH_email 			: "correo@dominio.com",
	TX_password 		: "Contraseña",
	TX_confirm  		: "Confirmar",
	TX_creating 		: "Creando cuenta...",
	PH_password 		: "(6-20 Carácteres)",
	PH_passwordConfirm 	: "Confirmar contraseña",
	PH_newProject 		: "Nombre del proyecto",
	PH_newGroup 		: "Nombre del grupo",
	PH_name 			: "nombre",
	PH_value 			: "valor",

			// HEADER
	BT_logout			: "Cerrar sesión", 	
	BT_templates		: "Plantillas", 	
	BT_profile			: "Perfil de usuario", 	
	BT_groups			: "Grupos", 	
	BT_views			: "Vistas", 	
	BT_controlPanel		: "Panel de control", 	

	
	BT_addProject 		: "Agregar Proyecto",
	BT_addGroup 		: "Agregar Grupo",
	BT_addNode  		: "Agregar Nodo",
	BT_addField  		: "Agregar Campo",

	TX_titDetail		: "Detalle",
	TX_fieldType		: "Tipo de Campo",
	TX_mask				: "Mascara",
	BT_saveConfig 		: "Guardar confirguración",

	BT_FAQ				: "F.A.Q.",

	IE_email			: "Correo electrónico válido es requerido",
	IE_password			: "Una contraseña es requerida",
	IE_passwordLen		: "La contraseña debe contener entre 6-20 carácteres",
	IE_confirmp			: "Las contraseñas no coinciden",

	IE_empty			: "Nombre de proyecto requiere 3 carácteres",
	IE_taken			: "Ya tienes un proyecto con ese nombre",
	IE_createdProject	: "El Proyecto fué creado con exito.",
	IE_createdProjectErr: "Error creando el proyecto.",
	IE_delProject 		: "El proyecto fue borrado con exito.",
	IE_createdGroup 	: "El grupo fue creado satisfactoriamente.",
	IE_createdGroupErr 	: "Error creando el grupo, base de datos no disponible.",
	IE_deleteGroup	 	: "Grupo borrado correctamente.",

	TX_about			: "Acerca de teranodo",
	TX_privacy			: "Privacidad",
	TX_terms			: "Terminos de uso",
	TX_updating			: "actualizando",

	BT_createAccount	: "Regístrate",

	TX_caps 			: "Mayúsculas activo",
	TX_existant			: "El e-mail ya ha sido previamente registrado.",

	TX_accept   		: 'Al hacer clic en "Regístrate", confirmas que aceptas nuestros',
	TX_privacy			: "Política de privacidad",
	TX_terms			: "Términos de servicio",
	TX_and 				: "y la ",
	TX_show				: "mostrar",
	TX_hide			    : "ocultar",

	NR_wrongPass  		: "Error de login, Revise su usuario y contraseña, Olvidaste tu contraseña?",

	TX_deleteProject 	: "¿Borrar Proyecto?.Todos los nodos y relaciones serán borrados permanentemente. Escriba el nombre para borrar  (sensible a Mayúsculas)."

}
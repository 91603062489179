import React, {useContext, List} from "react";
import { Link, withRouter } from "react-router-dom";
import {  withStyles } from '@material-ui/core/styles';

import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

import { Grid, Backdrop, CircularProgress, Button, Container } from "@material-ui/core";


import 'react-circular-progressbar/dist/styles.css';

import theme from '../../theme.js';

const styles = theme => ({
    
    title:{
        color : '#eb6324',
        fontSize : 24,
        marginTop : 40
    }

})
var user = null;

class UsoJusto extends React.Component {

    state ={
        data: { childs : []},
        msg : false,
        waiting : true,       
    } 

    render() {
        const { classes,  history, session } = this.props;
        const data = this.state.data
        
 
        return (
            

            <Grid container alignItems="center" justify="center" spacing={0}>
              

                <Container maxWidth='md'>
                <Grid  item>
                <img onClick={()=>{window.location="/"}} src="logofte.svg" style={{height:44, marginTop:30,cursor:'pointer'}}/>
                <div className={classes.title}>Política de uso justo (PREPAGO)</div>
                </Grid>

                <div >
    
          <div >

<p>La Política de Uso Justo aplica a todos los usuarios de la Red FTE</p>

<p>La Política de Uso Justo es una regulación en la velocidad de navegación de descarga que se aplica una vez que el usuario ha consumido su primera bolsa de datos. Esta regulación no implica restricción o limitante alguna en el acceso a las diversas aplicaciones, servicios, contenidos o sitios Web que el usuario desee utilizar; por lo que el usuario podrá continuar navegando, utilizando sus aplicaciones, descargando contenidos y accediendo a los sitios en Internet de su preferencia</p>

<p>La velocidad de navegación es reducida con la finalidad de evitar la sobresaturación de la red; de tal forma, que todos los usuarios puedan disfrutar del servicio con una velocidad promedio estable y de calidad. La velocidad se reducirá cuando se haya consumido por completo la primera bolsa de datos de la Oferta Comercial contratada antes de los 30 días de vigencia de la misma (20 GB para paquete FTE -200 y FTE – 300 y 30 GB para paquete FTE – 500). Se podrá continuar navegando por medio de la segunda bolsa de datos pero a una velocidad máxima de navegación downlink de 512 kbps (aplicable para los paquetes FTE – 200 y FTE – 300) y de 1024 kbps (Paquete FTE – 500).</p>

<p>En caso de que el Suscriptor requiera seguir navegando a velocidad completa podrá realizar una nueva recarga en cualquier Oferta Comercial. Sin embargo, los datos, minutos y SMS de la recarga anterior se perderán al no ser acumulables. </p>


          </div>
    </div>
           

                </Container>
                
            </Grid>

            
            

        )
    }

}


export default withStyles(styles)(UsoJusto);